import { ScenePlayerData } from "./tenhou";
import {
    REPLAY_STEP,
} from "./tenhou_types"
import {
    GameInfo,
    NakiData
} from "./tenhou_format"
import { d_tenhou_inv } from "./tenhou_dic";

export class Scene
{
    step:symbol;
    step_next:symbol;
    step_cnt:number;
    next_player:number;
    last_player:number;
    last_played_hai:string;
    rest_cnt:number;
    dora_cnt:number;
    playerdatas:ScenePlayerData[];
    reached:boolean[];

    constructor(){
        this.step = REPLAY_STEP.INVALID;
        this.step_next = REPLAY_STEP.INVALID;
        this.step_cnt = -1;
        this.next_player = -1;
        this.last_player = -1;
        this.last_played_hai ="";
        this.rest_cnt = 4*34;
        this.dora_cnt = 1;
        this.playerdatas=[new ScenePlayerData(),new ScenePlayerData(),new ScenePlayerData(),new ScenePlayerData()];
        this.reached=[false,false,false,false];
    }

    GetReached = ():boolean[] =>{ return this.reached;}
    GetRestCnt = ():number => { return this.rest_cnt; }
    GetDoraCnt = ():number => { return this.dora_cnt;}
    GetPlayerData = (player:number):ScenePlayerData => { return this.playerdatas[player];}
    GetLastPlayedHai = () => { return this.last_played_hai; }
    GetLastPlayer = ():number =>{return this.last_player;}
    
    GetStepNext() { return this.step_next; }
    GetNextPlayer() { return this.next_player; }
    IsEnd() { return (this.step_next == REPLAY_STEP.END); }
    
    IsErrorScene():boolean { return this.step==REPLAY_STEP.ERROR; }

    static fromJson(json:string):Scene
    {
        const ret = new Scene();
        const obj = JSON.parse(json);
  
        ret.step              = obj.step;
        ret.step_next         = obj.step_next;
        ret.step_cnt          = obj.step_cnt;
        ret.next_player       = obj.next_player;
        ret.last_player       = obj.last_player;
        ret.last_played_hai   = obj.last_played_hai;
        ret.rest_cnt          = obj.rest_cnt;
        ret.dora_cnt          = obj.dora_cnt;
    
        for(let i = 0; i < 4; i ++)
        {
            ret.reached[i] = obj.reached[i];
            ret.playerdatas[i].Copy(obj.playerdatas[i]);
        }

        console.log(ret);

        return ret;
    }

    static toJson(scene:Scene):string
    {
        return JSON.stringify(scene);
    }
}

export class SceneOperator
{
    scene:Scene;

    constructor(scene:Scene)
    {
        this.scene = scene;
    }

    SetScene(scene:Scene){ this.scene = scene; }

    DecRestCnt = ():void => {this.scene.rest_cnt--;}
    IncDoraCnt = ():void => {this.scene.dora_cnt++;}
    SetLastPlayedHai = (hai:string):void =>{
        if(hai==='')
        {
            //
        }
        else if(hai===undefined)
        {
            console.warn('undefined');
        }

        this.scene.last_played_hai = hai;
    }
    ClearLastPlayedHai() { this.scene.last_played_hai = ""; }
    
    IncStepCnt()                    { this.scene.step_cnt++; }
    SetStep(step:symbol)            { this.scene.step = step; }
    SetStepNext(step:symbol)        { this.scene.step_next = step; }
    SetNextPlayer(player:number)    { this.scene.next_player = player; }
    SetLastPlayer(player:number)    { this.scene.last_player = player; }

    AddFuroFromOther(target_player:number,naki:NakiData):void
    {
        this.scene.GetPlayerData(target_player).AddFuroFromOther(naki);
    }
    AddNakareInfo(nakare_player:number):void
    {
        this.scene.GetPlayerData(nakare_player).AddNakareInfo();
    }
    Dahai(player:number,hai_cnv:string,furo:boolean,tsumogiri:boolean,reach:boolean)
    {
        this.scene.GetPlayerData(player).Dahai(hai_cnv,furo,tsumogiri,reach);
        if(reach)
        {
            this.scene.reached[player] = true;
        }
    }
}

const FirstRestCnt:number = 4 * 34 -13 * 4;

export function MakeInitialScene(datas:any,next_player:number):Scene
{
    const scene = new Scene();

    for(let i = 0; i < 4; i++)
    {
        scene.playerdatas[i].SetInitialDataFromPlayData(datas[i]);
    }
    scene.step          = REPLAY_STEP.START;
    scene.step_next     = REPLAY_STEP.TSUMO;
    scene.step_cnt      = 0;
    scene.next_player   = next_player;
    scene.last_player   = -1;

    scene.dora_cnt      = 1;
    scene.rest_cnt      = FirstRestCnt;

    return (scene);
}

export function CloneScene(scene:Scene):Scene
{
    const new_scene = new Scene();

    new_scene.step              = scene.step;
    new_scene.step_next         = scene.step_next;
    new_scene.step_cnt          = scene.step_cnt;
    new_scene.next_player       = scene.next_player;
    new_scene.last_player       = scene.last_player;
    new_scene.last_played_hai   = scene.last_played_hai;
    new_scene.rest_cnt          = scene.rest_cnt;
    new_scene.dora_cnt          = scene.dora_cnt;

    for(let i = 0; i < 4; i ++)
    {
        new_scene.reached[i] = scene.reached[i];
        new_scene.playerdatas[i].Copy(scene.playerdatas[i]);
    }
    return new_scene;
}

export function GetDoras(scene:Scene, info:GameInfo):string[]
{
    let cnt = 0;
    const ret = [];
    const doras = info.doras;
    for ( let i = 0; i < doras.length; i++)
    {
        ret.push(d_tenhou_inv[String(doras[i])]);
        cnt++;
        if(scene.GetDoraCnt()==cnt)
        {
            break;
        }
    }
    for (let j = cnt; j<5; j++)
    {
        ret.push("0z");
    }
    return ret;
}
