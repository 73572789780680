import { 
    getDatabase, 
    ref,
    push,
    query,
    onValue,
    orderByChild,
} from "firebase/database";
import { defineStore } from 'pinia';

import {
    useAuthStore
} from "@/stores/auth"

import {
    getTime
} from "@/lib/util"

export interface CommentData{
    id:string,
    comment:any,
}
interface State{
    commentDataList:Map<string,any>;
    isLoading:boolean;
    isKickedLoading:boolean;
}

export const useCommentsStore = defineStore({
    id: 'comments',
    state: ():State => {
        return {
            commentDataList: new Map<string,any>(),
            isLoading:true,
            isKickedLoading:false,
        };
  },
  getters: {
    getPaifuComments:(state) =>{
        return (paifuId:string) =>{
            // return state.commentDataList.get(paifuId);

            const ret = new Map<string,any>();

            state.commentDataList.forEach((value,key)=>{
                if(value['paifuId']===paifuId)
                {
                    ret.set(key,value);
                }
            });
            return ret;
        };
    },
    getUserComments:(state) =>{
        return (userId:string) =>{
            // return state.commentDataList.get(paifuId);

            const ret = new Map<string,any>();
            state.commentDataList.forEach((value,key)=>{
                if(value['user']===userId)
                {
                    ret.set(key,value);
                }
            });
            return ret;
        };
    },
    getComments:(state) =>{
        return state.commentDataList;
    }
  },
  actions: {
    async recieveCommentDatas() {
        try {
            const database = getDatabase();
       
            const path = "comments";
            const Ref = query(
                ref(database,path),
                orderByChild("paifuId"),
            );
            this.isKickedLoading = true;

            onValue(Ref,(snapshot)=>{
                const dat = snapshot.val();
                // console.log(dat);
                snapshot
                .forEach((child)=>{
                //   console.log(child.child("content").val());
                //   console.log(child.key);
                  this.commentDataList.set(child.key as string,child.val());
                });
                // console.log("comment",this.commentDataList);
                this.isLoading = false;
                this.isKickedLoading = false;
            });        
        }catch(err){
            if(err instanceof Error) {
                // const errorStore = useErrorStore();
                // errorStore.setError(err);
                console.log(err);
                return;
            }
            this.isLoading = false;
            this.isKickedLoading = false;
            throw err;
        }
    },
    async postComment( 
        arg={
             comment:String,
             paifuId:String
        }
    ):Promise<boolean>{
        const auth = useAuthStore();
        if(auth.isLoggedIn)
        {
            const user = auth.currentUser;
            if(user==null){
                return false;
            }
            const database = getDatabase();
            // const path = 'comments/' + arg.paifuId + '/';
            const path = 'comments/';
            const Ref = ref(database,path);
            push(
                Ref,
                {
                    comment:arg.comment,
                    paifuId:arg.paifuId,
                    time:getTime(),
                    user:user.uid
                }
            ).then((result)=>{
                console.log( "user entered", result);
                return true;
            });
        }
        else
        {
            console.log("user not loggedIn");
            return false;
        }
        return false;
    }
  },
});