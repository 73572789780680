import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { createPinia } from "pinia"

loadFonts()

import {firebaseConfig} from "@/config/local"

  // Import the functions you need from the SDKs you need

  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  // window.firebase = app;
  // window.database = getDatabase(app);

  // console.log(app);
  // console.log(analytics);

import { useResourcesStore } from './stores/resources'



createApp(App)
  .use(router)
  .use(createPinia())
  .use(vuetify)
  .mount('#app')

  const resourcesStore = useResourcesStore();

resourcesStore.prepare();  