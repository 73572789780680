import * as PIXI from"pixi.js"
import {
    NAKI,
    KAZE,
} from "./tenhou_types"
import {
    Scene,
    GetDoras,
} from "./tenhou_scene"
import { 
    GameInfo,
    TenhouFormat
} from "./tenhou_format";

import {
    useResourcesStore
} from "@/stores/resources"
import { Graphics } from "@thegraid/createjs-module";

class PaifuDisplayerCtx_PIXI
{
    pixi:PIXI.Application|null;
    images:any;    

    hai_w:number;
    hai_h:number;

    canvas_width:number;
    canvas_height:number;

    constructor(){
        this.pixi = null;
        this.images = null;

        this.hai_w = 33;
        this.hai_h = 45;

        this.canvas_width = 640;
        this.canvas_height = 640;
    }
    GetStage():PIXI.Application|null{ return this.pixi; }
    GetImages():any{return this.images;}
    Set(pixi:PIXI.Application,images:any):void{
        this.pixi = pixi;
        this.images = images;

        // console.log("Set stage:\n",stage);
        // console.log("Set images:\n",images);
    }
    Terminate(){
        if(this.pixi)
        {
            this.pixi.destroy();
        }
        this.images = null;
    }
    GetHaiW():number
    {
        return this.hai_w;
    }
    GetHaiH():number
    {
        return this.hai_h;
    }
    GetCanvasWidth():number{ return this.canvas_width;}
    GetCanvasHeight():number{ return this.canvas_height;}

    CreateSprite(pai:string){
        // return PIXI.Sprite.from(this.images[pai]);
        return new PIXI.Sprite(this.GetImage(pai));
    }
    // CreateSprite(pai:string){return new PIXI.Sprite(this.images[pai]);}

    GetImage(pai:string)
    {
        return useResourcesStore().gettextures().get(pai);
        // return this.images[pai];
    }
}

export class PaifuDisplayerConfig
{
    hidden_mode:boolean;
    nakare_disp:boolean;
    hide_name:boolean;
    view_id:number;
    scene_index:number;

    constructor(){
        this.hidden_mode = true;
        this.nakare_disp =true;
        this.hide_name = true;
        this.view_id = 0;
        this.scene_index = 0;
    }

    SetHidden(f:boolean):void { this.hidden_mode = f;}
    SetNakare(f:boolean):void {this.nakare_disp = f;}
    SetHideName(f:boolean):void {this.hide_name = f;}
    SetView(view:number):void{this.view_id = view;}
    SetSceneIndex(index:number):void{this.scene_index = index;}

    IsHidden():boolean{ return this.hidden_mode;}
    IsNakare():boolean{ return this.nakare_disp;}
    IsHideName():boolean { return this.hide_name;}
    GetView():number{return this.view_id;}
    GetSceneIndex():number{return this.scene_index;}

    static fromJson(json:string):PaifuDisplayerConfig
    {
        const ret = new PaifuDisplayerConfig();
        const obj = JSON.parse(json);
        ret.hidden_mode = obj['hidden_mode'];
        ret.nakare_disp = obj['nakare_disp'];
        ret.hide_name = obj['hide_name'];
        ret.view_id = obj['view_id'];
        ret.scene_index = obj['scene_index'];

        // console.log(ret);

        return ret;
    }

    static toJson(config:PaifuDisplayerConfig):string
    {
        return JSON.stringify(config);
    }

    Copy(config:PaifuDisplayerConfig):void
    {
        this.hidden_mode = config.hidden_mode;
        this.nakare_disp = config.nakare_disp;
        this.hide_name = config.hide_name;
        this.view_id = config.view_id;
        this.scene_index = config.scene_index;
    }
}


export class PaifuDisplayer_PIXI
{
    ctx:PaifuDisplayerCtx_PIXI;

    constructor(){
        this.ctx        = new PaifuDisplayerCtx_PIXI();
    }

    CanvasWidth():number{ return this.ctx.canvas_width; }
    CanvasHeight():number{ return this.ctx.canvas_height;}
    CanvasHalfW():number{ return this.CanvasWidth()/2; }
    CanvasHalfH():number{return this.CanvasHeight()/2;}
    HaiW():number{ return this.ctx.hai_w;}
    HaiH():number{return this.ctx.hai_h;}
    Pixi():PIXI.Application|null{ return this.ctx.pixi; }

    SetCtx(stage:PIXI.Application,images:any):void
    {
        this.Terminate();

        this.ctx.Set(stage,images);        
    }

    Initialize(canvasname:string,images:any):void
    {
        // console.log("Initialize pixi");

        const stage = new PIXI.Application({
            view:document.getElementById(canvasname) as HTMLCanvasElement,
            width:this.CanvasWidth(),
            height:this.CanvasHeight(),
            backgroundColor:0x2c3e50,
            preserveDrawingBuffer:true
        });
        this.SetCtx(stage,images);
    }
    Terminate()
    {
        // console.log("Terminate pixi");
        this.ctx.Terminate();
    }

    async ClearScene():Promise<void>
    {
        const pixi = this.Pixi();
        pixi?.stage.destroy({children:true});
        if(pixi)
        {
            pixi.stage = new PIXI.Container();
        }
    }
   
    DispSceneError(container:PIXI.Container,config:PaifuDisplayerConfig,info:GameInfo, tenhou:TenhouFormat)
    {
        const hw = new PIXI.Text(
            "牌譜が正しくありません",
            { 
                fontSize:24,
                fill:"white"
            }
            );
        hw.x = 100;
        hw.y = 96;
        // hw.textAlign = "center";
        // hw.textBaseline = "bottom";
        container.addChild(hw);
        const bounds = hw.getLocalBounds();
        hw.x = 100 - (bounds.width/2);//center
        hw.y = 96 - (bounds.height/2);//
    }

    async DispScene(scene:Scene, config:PaifuDisplayerConfig, info:GameInfo, tenhou:TenhouFormat):Promise<void>
    {
        const pixi = this.Pixi();
        pixi?.stage.destroy({children:true});
        if(pixi)
        {
            pixi.stage = new PIXI.Container();
        }
        const pixiScene = pixi?.stage;
        if(pixiScene==null){return;}

        if(scene.IsErrorScene())
        {
            this.DispSceneError(pixiScene,config,info,tenhou);
            return;
        }

        const half_w = this.CanvasHalfW();
        const half_h = this.CanvasHalfH();

        const stuts = info.stuts;
        const doras = GetDoras(scene,info);

        // console.log("config",config);
        
        {
            const container = disp_hud( 
                this.ctx,
                config.GetSceneIndex(),
                config.view_id,
                scene.GetRestCnt(), 
                info.points,
                scene.GetLastPlayer(),
                scene.GetReached(),
                stuts,
                doras
                );
            container.x += half_w;
            container.y += half_h;
            pixiScene.addChild(container);
        }

        {
            const container = new PIXI.Container();
            const hide_names:string[] = ["player1","player2","player3","player4"];

            // console.log("tenhou.GetStartPlayer()",tenhou.GetStartPlayer());
            for(let player_index = 0; player_index < 4; player_index++)
            {
                const container_player = new PIXI.Container();
                const kaze = ( 4 + player_index  - tenhou.GetStartPlayer() ) % 4;
                const seat_pos = (player_index + (4-config.view_id))%4;
                
                // console.log("player 自風 席",player_index,KAZE[kaze],seat_pos);

                const playerData = scene.GetPlayerData(player_index);
                let point = info.points[player_index];
                const isReach:boolean = scene.reached[player_index];
                if(isReach){ point = point - 1000;}

                const name =  config.IsHideName() ? hide_names[player_index] : tenhou.name[player_index];
                const hidden = (config.hidden_mode && (config.view_id != player_index));
                const explain = KAZE[kaze] + "家 : " + name + " " + point;

                const container_sutehai = this.DispSutehaiPlayer(
                    playerData.sutehai,
                    seat_pos,
                    config.nakare_disp
                );
                const container_tehai = this.DispTehaiPlayer(
                    playerData.tehai,
                    playerData.tsumo,
                    seat_pos,
                    hidden,
                    explain
                );
                const container_naki = this.DispNakiPlayer(
                    playerData.naki,
                    seat_pos,
                );
                container_player.addChild(container_sutehai);
                container_player.addChild(container_tehai);
                container_player.addChild(container_naki);

                // container_player.rotation = player2angle(player_index);

                container.addChild(container_player);
            }
            // 全体をViewに合わせて回転.
            // したかったがうまく動作しないので保留.中でやる.
            // container.rotation = player2angle(config.view_id);
            pixiScene.addChild(container);
        }

        // Tickerで管理する.
        // stage.update();
    }

    DispSutehaiPlayer( 
            pai_array:any,
            player:number,
            disp_nakare:boolean
        ):PIXI.Container
    {
        const angle = player2angle(player);
        const half_w = this.CanvasHalfW();
        const half_h = this.CanvasHalfH();
        const w = 100;
        const h = 100;
        const i = ((player%3)==0) ? -1:1;
        const j = ((player/2)< 1) ? 1:-1;
        const x = half_w + w*i;
        const y = half_h + h*j;
        
        const ret = disp_sutehai(
            this.ctx,
            pai_array,
            disp_nakare,
        );
        ret.x = x;
        ret.y = y;
        ret.angle = angle;

        return ret;
    }

    DispTehaiPlayer(
        pai_array:any,
        pai_tsumo:any,
        player:number,
        hidden:boolean,
        player_name:string,
        ):PIXI.Container
    {
        const angle = player2angle(player);
        const half_w = this.CanvasWidth()/2;
        const half_h = this.CanvasHeight()/2;
        const w = 296;
        const h = 296;
        const i = ((player%3)==0) ? -1:1;
        const j = ((player/2)< 1) ? 1:-1;
        const x = half_w + w*i;
        const y = half_h + h*j;

        const ret = disp_tehai(
            this.ctx,
            pai_array,
            pai_tsumo,
            hidden,
            player_name
        );

        ret.x = x;
        ret.y = y;
        ret.angle = angle;

        return ret;
    }

    DispNakiPlayer( naki:any, player:number):PIXI.Container
    {
        const angle = player2angle(player);
        const half_w = this.CanvasWidth()/2;
        const half_h = this.CanvasHeight()/2;
        const w = 300;
        const h = 300;
        const i = ((player%3)==0) ? -1:1;
        const j = ((player/2)< 1) ? 1:-1;
        const x = half_w + w*i;
        const y = half_h + h*j;
        
        const ret = disp_naki(
            this.ctx,
            naki
        );
        ret.x = x;
        ret.y = y;
        ret.angle = angle;

        return ret;
}
}

function player2angle(player:number):number
{
    return ((4-player)%4)*90;
}

function disp_hud(
    ctx:PaifuDisplayerCtx_PIXI,
    sceneNumber:number,
    view_id:number, 
    restcnt:number, 
    points:number[],
    current_player:number,
    reached:boolean[],
    stuts:any, 
    doras:string[]
    ):PIXI.Container
{
    // console.log("view_id:",view_id);
    // console.log("images:\n",images);

    const containerBase = new PIXI.Container();

    // const sq = new createjs.Shape();
    // sq.graphics.beginFill("DrakGray").drawRect(0,0,200,200);
    {
        const graphics = new PIXI.Graphics();
        graphics.beginFill(0x202020);
        graphics.drawRoundedRect(0,0,200,200,10);
        graphics.endFill();
        containerBase.addChild(graphics);
        containerBase.position.set(-100,-100);
    }
    {
        const graphics2 = new PIXI.Graphics();
        graphics2.beginFill(0x205040);
        graphics2.drawRoundedRect(32,76,136,92,12);
        graphics2.endFill();
        containerBase.addChild(graphics2);
        // containerBase.position.set(-64,-64);
    }

    {
        const container = new PIXI.Container();
        container.x = 16;
        container.y = 4;
        containerBase.addChild(container);

        let x_ = 0;
        const y_ = 0;

        // for (let i = 0; i < doras.length; i++)
        for (let i = 0; i < 5; i++)
        {
            let pai = '0z';
            if(i < doras.length)
            {
                pai = doras[i];
            }

            // let pai = doras[i];
            // console.log("dora pai:",pai);
            const bmp2 = ctx.CreateSprite(pai);
            const w_ = ctx.GetHaiW();
            const h_ = ctx.GetHaiH();
            bmp2.x = x_;
            bmp2.y = y_;
            // bmp2.cache(0,0,w_,h_);
            container.addChild(bmp2);
            x_ += w_;
        }
    }

    {
        const ba = KAZE;

        // const hw = new createjs.Text(ba[stuts[0]%4]+ (stuts[0]%4+1) + "局", "22px sans-serif", "white");
        const hw = new PIXI.Text(ba[Math.floor(stuts[0]/4)]+ (stuts[0]%4+1) + "局",
        { fontSize:24, fill:"white"});
        hw.x = 100;
        hw.y = 96;
        // hw.textAlign = "center";
        // hw.textBaseline = "bottom";
        containerBase.addChild(hw);
        const bounds = hw.getLocalBounds();
        hw.x = 100 - (bounds.width/2);//center
        hw.y = 96 - (bounds.height/2);//

        // const hw2 = new createjs.Text("本場:"+stuts[1], "18px sans-serif", "white");
        const hw2 = new PIXI.Text("本場:"+stuts[1], 
        {fontSize:20, fill:"white"});
        hw2.x = 35;
        hw2.y = 110;
        // hw2.textBaseline = "bottom";
        containerBase.addChild(hw2);

        // const hw3 = new createjs.Text("供託:"+stuts[2], "18px sans-serif", "white");
        const hw3 = new PIXI.Text("供託:"+stuts[2], {fontSize:20, fill:"white"});
        hw3.x = 105;
        hw3.y = 110;
        // hw3.textBaseline = "bottom";
        containerBase.addChild(hw3);
    }

    {
        const hw = new PIXI.Text("#"+sceneNumber, {
            fontSize:22,fill:"yellow"
        });
        hw.x = 36;
        hw.y = 140;
        // hw.textAlign = "center";
        // hw.textBaseline = "bottom";
        containerBase.addChild(hw);

    }

    {
        // const hw = new createjs.Text("残："+restcnt, "18px sans-serif", "white");
        const hw = new PIXI.Text("残:"+restcnt, {
            fontSize:22,fill:"white"
        });
        hw.x = 104;
        hw.y = 140;
        // hw.textAlign = "center";
        // hw.textBaseline = "bottom";
        containerBase.addChild(hw);
    }
    // console.log("view_id:",view_id);
    for (let player = 0; player < 4; player++)
    {
        const jikaze = ((4- stuts[0]%4)+player)%4;
        const seat_pos = (4 + player - view_id) %4;

        // console.log("player:",player,"jikaze",jikaze,"seat_pos",seat_pos,"angle:",player2angle(seat_pos));

        // console.log("player+view_id",player+view_id ," view_id:",view_id," stuts[0]%4:",stuts[0]%4, " player:",player," seat:",seat, " view_pos:",view_pos );

        const container = disp_hud_player( 
            ctx,
            current_player == player,
            reached[player],
            points[player],
            jikaze
        );
        // 回転させる.
        // container.rotation = player2angle(view_id);

        const angle = player2angle(seat_pos);

        const w = 100;
        const h = 100;
        // const i = ((player%3)==0) ? -1:1;
        // const j = ((player/2)< 1) ? 1:-1;
        const i = ((seat_pos%3)==0) ? -1:1;
        const j = ((seat_pos/2)< 1) ? 1:-1;
        const xx = 100 + w*i;
        const yy = 100 + h*j;

        container.x = xx;
        container.y = yy;
        container.angle = angle;
        if(seat_pos==3)
        {
            container.y += 40;
        }
        else if(seat_pos==2)
        {
            container.y += 48;
        }
        else if(seat_pos==1)
        {
            container.y += 20;
        }

        containerBase.addChild(container);
    }

    return containerBase;
}

function disp_hud_player( 
    ctx:PaifuDisplayerCtx_PIXI,
    current_player:boolean,
    reach:boolean, 
    point:number, 
    jikaze:number
    ):PIXI.Container
{
    const container = new PIXI.Container();

    if(current_player)
    {
        const hw3 = new PIXI.Graphics();
        hw3.beginFill("Yellow");
        hw3.drawRect(28,-8,120,3);
        hw3.endFill();
        container.addChild(hw3);
    }

    const hw = new PIXI.Text( 
        String(point), 
        {fontSize:22, fill:"white"}
    );
    hw.x = 80;
    hw.y = -24 - 3;
    // hw.textAlign = "center";
    // hw.textBaseline = "bottom";
    container.addChild(hw);

    const jikaze_texts = KAZE;

    const hw2 = new PIXI.Text( 
        jikaze_texts[jikaze],
        {fontSize:24, fill:"white"}
    );
    hw2.x = 14;
    //if(seat==3)
    {
        hw2.x +=20;
    }
    hw2.y = -28 - 3;
//                hw2.textAlign = "center";
    // hw2.textBaseline = "bottom";

    if(reach)
    {
        const bmp2 = ctx.CreateSprite("1000t");
        bmp2.x = 80;
        bmp2.y = -40;
        container.addChild(bmp2);
    }

    container.addChild(hw2);

    return container;
}

function disp_tehai( 
    ctx:PaifuDisplayerCtx_PIXI,
    pai_array:any,
    pai_tsumo:any,
    hidden:boolean,
    player_name:any
    ):PIXI.Container
{
    const container = new PIXI.Container();

    const hai_w = ctx.GetHaiW();
    const hai_h = ctx.GetHaiH();


    let x_ = 80;
    const y_ = -hai_h/2 - 4;

// 名前表示.
   {
       const hw3 = new PIXI.Graphics();
       hw3.beginFill("Yellow");
       hw3.drawRect(80,-56,320,24);
       hw3.endFill();
       container.addChild(hw3);
   }

  {
      const hw = new PIXI.Text(player_name,{fontSize:20,fill:"black"});
  //    stage.addChild(hw);
      hw.x = 84;
      hw.y = -54;
      container.addChild(hw);
  }


    for (let i = 0; i < pai_array.length; i++)
    {
        let pai = pai_array[i];
        let tsumogiri = false;
        let nakare = false;
        let reach = false;
        if(pai.indexOf('N')!=-1)
        {
            pai = pai.replace('N','');
            nakare = true;
        }
        if(pai.indexOf('r')!=-1)
        {
            pai = pai.replace('r','');
            reach = true;
        }
        if(pai.indexOf('Q')!=-1)
        {
            pai = pai.replace('Q','');
            tsumogiri = true;
        }
        if(tsumogiri)
        {
            //
        }
        if(reach)
        {
            pai = pai + '_';
        }
        if(nakare)
        {
        //
        }
        if(hidden)
        {
            pai = "0z";
        }
        const bmp2 = ctx.CreateSprite(pai);
        const w_ = reach ? hai_h : hai_w;
        const h_ = reach ? hai_w : hai_h;
        bmp2.x = x_;
        bmp2.y = y_;
        // bmp2.cache(0,0,w_,h_);
        container.addChild(bmp2);

       x_ += w_;
        // x_ += bmp2.getBounds().width;

    }
    if(pai_tsumo==='')
    {
        //
    }
    else
    {
        let pai = pai_tsumo;
        if(hidden)
        {
            pai = "0z";
        }

        const bmp2 = ctx.CreateSprite(pai);
        bmp2.x = x_+20;
        bmp2.y = y_;
        container.addChild(bmp2);
    }

    // {
    //     const hw3 = new PIXI.Graphics();
    //     hw3.beginFill("Red");
    //     // hw3.drawPolygon()
    //     hw3.drawRect(400,-100,80,60);
    //     hw3.endFill();
    //     container.addChild(hw3);

    //     {
    //         const hw = new PIXI.Text("チー",{fontSize:32,fill:"black"});
    //     //    stage.addChild(hw);
    //         hw.x = 400+16;
    //         hw.y = -100+16;
    //         container.addChild(hw);
    //         // drawStar(hw, 0,0,5,100,200);
    //     }
    // }

    return container;
}

// function drawStar(target,
//     x,
//     y,
//     points,
//     innerRadius,
//     outerRadius,
//     angle = 0) {
// const step = (Math.PI * 2) / points;
// const halfStep = step / 2;
// const start = (angle / 180) * Math.PI;
// let n, dx, dy;
// target.moveTo(
// x + (Math.cos(start) * outerRadius),
// y - (Math.sin(start) * outerRadius)
// );

// for (n = 1; n <= points; ++n) {
// dx = x + Math.cos(start + (step * n) - halfStep) * innerRadius;
// dy = y - Math.sin(start + (step * n) - halfStep) * innerRadius;
// target.lineTo(dx, dy);
// dx = x + Math.cos(start + (step * n)) * outerRadius;
// dy = y - Math.sin(start + (step * n)) * outerRadius;
// target.lineTo(dx, dy);
// }
// }

function disp_naki( 
    ctx:PaifuDisplayerCtx_PIXI,
    naki:any
    ):PIXI.Container
{
    const hai_w = ctx.GetHaiW();
    const hai_h = ctx.GetHaiH();
    const container = new PIXI.Container();

    // console.log( "hai_wh:", hai_w, ",", hai_h);

    let x_ = 0;
    const y_ = -hai_h/2 - 10;

    let ww = 0;

    for (let j = naki.length-1; j >= 0; j--)
    {
        const naki_ = naki[j];
        const pai_array = [];
        if(naki_.naki === NAKI.ANKAN)
        {
            for( let i = 0; i < naki_.hais.length; i++)
            {
                if(i===0 || i===3)
                {
                    pai_array.push('0z');
                }
                else
                {
                    pai_array.push(naki_.hais[i]);
                }
            }
        }
        else
        {
            for( let i = 0; i < naki_.hais.length; i++)
            {
                if(i==3-naki_.from)
                {
                    pai_array.push('r'+naki_.hais[i]);
                }
                else
                {
                    pai_array.push(naki_.hais[i]);
                }
            }
        }

        for (let i = 0; i < pai_array.length; i++)
        {
            let pai = pai_array[i];
            let tsumogiri = false;
            let nakare = false;
            let reach = false;
            if(pai.indexOf('N')!=-1)
            {
                pai = pai.replace('N','');
                nakare = true;
            }
            if(pai.indexOf('r')!=-1)
            {
                pai = pai.replace('r','');
                reach = true;
            }
            if(pai.indexOf('Q')!=-1)
            {
                pai = pai.replace('Q','');
                tsumogiri = true;
            }
            if(tsumogiri)
            {
                //
            }
            let ofs_y = 0;

            if(reach)
            {
                pai = pai + '_';
                ofs_y = 11;
            }
            if(nakare)
            {
            //
            }
            const bmp2 = ctx.CreateSprite(pai);
            const w_ = reach ? hai_h : hai_w;
            const h_ = reach ? hai_w : hai_h;

            // console.log( "bmp2:",x_, ",",y_ );

            bmp2.x = x_;
            bmp2.y = y_ + ofs_y;
            // bmp2.cache(0,0,w_,h_);
            container.addChild(bmp2);
            // x_ += bmp2.getBounds().width;
            x_ += w_;

            ww += w_;
        }
        const space = 4;
        ww += space;
        x_ += space;
    }

    for (let i = 0; i < container.children.length; i++)
    {
        container.children[i].x += -20 + 640 -  ww;
        // console.log( "child:",i, "(", container.children[i].x,",", container.children[i].y,")" );
    }

    return container;
}


function disp_sutehai(
    ctx:PaifuDisplayerCtx_PIXI,
    pai_array:string[],
    disp_nakare:boolean
    ):PIXI.Container
{
    // console.log( "sutehai ", x, " ", y );

    const container = new PIXI.Container();
    const hai_w = ctx.GetHaiW();
    const hai_h = ctx.GetHaiH();

    let x_ = 0;
    let display_cnt = 0;

    for (let i = 0; i < pai_array.length; i++)
    {
        let pai = pai_array[i];
        let tsumogiri = false;
        let nakare = false;
        let reach = false;
        if(pai.indexOf('N')!=-1)
        {
            pai = pai.replace('N','');
            nakare = true;
            if(!disp_nakare)
            {
                continue;
            }
        }
        if(pai.indexOf('r')!=-1)
        {
            pai = pai.replace('r','');
            reach = true;
        }
        if(pai.indexOf('Q')!=-1)
        {
            pai = pai.replace('Q','');
            tsumogiri = true;
        }
        if(reach)
        {
            pai = pai + '_';
        }
        const bmp2 = ctx.CreateSprite(pai);
        const w_ = reach ? hai_h : hai_w;
        const h_ = reach ? hai_w : hai_h;
        if(display_cnt%6==0 && display_cnt < 18)
        {
            x_ = 0;
        }
        bmp2.x = x_;
        let ii = Math.floor(display_cnt/6);
        if(ii >2){ii=2;}
        bmp2.y = ii*hai_h;
        SetBmpFilter(bmp2,tsumogiri,nakare);
        // bmp2.cache(0,0,w_,h_);
        container.addChild(bmp2);
        display_cnt++;
        x_ += w_;
    }
    return container;
}

function SetBmpFilter(bmp:PIXI.Sprite, tsumogiri:boolean, nakare:boolean):void
{
    if(tsumogiri)
    {
        // bmp.tint = 0xF0F0C0;
        bmp.tint = 0xA0A0A0;
    }
    if(nakare)
    {
        const graphics = new PIXI.Graphics();
        graphics.lineStyle(3,0xFE7777,1);
        // graphics.beginFill(0xDE3249);
        graphics.drawRect(0,0,bmp.width,bmp.height);
        graphics.endFill();
        bmp.addChild(graphics);
    }
}