import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import {getAuth} from 'firebase/auth'

import SignUp from "@/components/SignUp.vue"
import AppTop from "@/views/AppTop.vue"
import PaifuInput from "@/views/PaifuInput.vue"
import PaifuViewListSimple from "@/views/PaifuViewListSimple.vue"
import PaifuViewDetail from "@/views/PaifuViewDetail.vue"
import UserInfo from "@/views/UserInfo.vue"
import MyPage from "@/views/MyPage.vue"

import { usePaifusStore } from '@/stores/paifus'
import { useCommentsStore } from '@/stores/comments'
import {useUsersStore} from '@/stores/users'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: AppTop,
    meta:{
      requiresAuth:true,
      breadcrumb:{
        label:'',
      }
    }
  },
  {
    path:"/signup",
    name:"SignUp",
    component:SignUp
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta:{
      breadcrumb:{
        label:"About",
        // parent:'home'
      }
    }
  },
  {
    path:"/paifuInput",
    name:"PaifuInput",
    component:PaifuInput,
    meta:{
      requiresAuth:true,
      breadcrumb:{
        label:'牌譜投稿',
        // parent:'home'
      }
    }
  },
  {
    path:"/PaifuViewListSimple",
    name:"PaifuViewListSimple",
    component: PaifuViewListSimple,
    meta:{
      requiresPaifuDatas:true,
      breadcrumb:{
        label:'牌譜一覧',
        // parent:'home'
      }
    }
  },
  {
    path:"/PaifuDetail/:paifuId?",
    name:"PaifuDetail",
    component:PaifuViewDetail,
    props:true,
    meta:{
      // requiresAuth:true,
      requiresPaifuDatas:true,
      breadcrumb:{
        label:'牌譜',
        parent:'PaifuViewListSimple'
      }
    },
  },
  {
    path:"/UserInfo/:uid?",
    name:"UserInfo",
    component:UserInfo,
    props:true,
    meta:{
      requiresPaifuDatas:true,
      breadcrumb:{
        label:'ユーザー情報',
        // parent:'home'
      }
    },
  },
  {
    path:"/MyPage",
    name:"MyPage",
    component:MyPage,
    meta:{
      requiresPaifuDatas:true,
      requiresAuth:true,
      breadcrumb:{
        label:'My Page',
        // parent:'home'
      }
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to,from,next)=>{
  const requiresAuth = to.matched.some(record=>record.meta.requiresAuth);
  const currentUser = getAuth().currentUser;
  // console.log(requiresAuth);
  if(requiresAuth){
    if(!currentUser){
      next({
        path: "/signup",
        query:{redirect:to.fullPath}
      });
    } 
    else {
      const requiresPaifuDatas = to.matched.some(record=>record.meta.requiresPaifuDatas);
      if(requiresPaifuDatas)
      {
          //
          const paifusStore = usePaifusStore();
          paifusStore.recievePaifuDatas();
          
          const commentsStore = useCommentsStore();
          commentsStore.recieveCommentDatas();

          const usersStore = useUsersStore();
          usersStore.recieveUserDatas();
      }

      next();
    }
  } 
  else{
    const requiresPaifuDatas = to.matched.some(record=>record.meta.requiresPaifuDatas);
    if(requiresPaifuDatas)
    {
        //
        const paifusStore = usePaifusStore();
        paifusStore.recievePaifuDatas();
        
        const commentsStore = useCommentsStore();
        commentsStore.recieveCommentDatas();

        const usersStore = useUsersStore();
        usersStore.recieveUserDatas();
    }

    next();
  }
});

export default router
