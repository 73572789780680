// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme:{
    defaultTheme:'myCustomTheme',
    themes:{
      myCustomTheme:{
        dark:false,
        colors:{
          appbar:"#484848",
          cherry:"#D1C4E9",
          // cherry2:"#EDE7F6"
          cherry2:"#EEEEEE",
          cherry3:"#817499",
        }
      }
    }
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
})
