export const toBlob = function(base64)
{
    var bin = atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    let blob = null;
    // Blobを作成
    try{
            blob = new Blob([buffer.buffer], {
            type: 'image/png'
        });
    }catch (e){
        return null;
    }
    return blob;
 }

 export const getTime = function()
 {
    let now = new Date();
    return now.getTime();
 }

 export const timeToLocaleString = function(time)
 {
    let date = new Date();
    date.setTime(Number(time));
    const st = date.toLocaleString();
    return st.substring(0,st.length-3);
 }