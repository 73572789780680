import {
    // KAZE,
    NAKI,
    TYPE,
    REPLAY_STEP,
    d_tenhou_inv,
    d_hai_sort,
} from "./tenhou_types"

export class GameInfo
{
    constructor(){
        this.stuts = [];
        this.points =[];
        this.doras=[];
        this.uradoras=[];
    }
    GetStartPlayer()
    {
        return this.stuts[0]%4;
    }
}

export class TenhouFormat
{
    constructor(){
        this.title = "";
        this.name = [];
        this.rule = "";
        this.log = "";

        this.info = new GameInfo();
        this.playdatas = [new PlayData(),new PlayData(),new PlayData(),new PlayData()];
        
    }
    Dump()
    {
        console.log(this.title);
        console.log(this.name);
        console.log(this.rule);
        console.log(this.log);

        console.log(this.info);
        console.log(this.playdatas);
    }

    Set(json)
    {
        const obj = JSON.parse(json);

        this.title = obj.title;
        this.name = obj.name;
        this.log = obj.log;
        this.rule = obj.rule;

        var list = Object.assign([],this.log[0]);

        this.info.stuts = Object.assign([],list[0]);
        this.info.points = Object.assign([],list[1]);
        this.info.doras = Object.assign([],list[2]);
        this.info.uradoras = Object.assign([],list[3]);

        for (var i = 0; i < 4; i++)
        {
            this.playdatas[i].haipai = Object.assign([],list[4+i*3]);
            this.playdatas[i].tsumohai = Object.assign([],list[4+i*3+1]);
            this.playdatas[i].sutehai = Object.assign([],list[4+i*3+2]);        
        }
    }
    GetPlayDatas()
    {
        return this.playdatas;
    }
    GetStartPlayer()
    {
        return this.info.GetStartPlayer();
    }
}

export class NakiData
{
    constructor(){
        this.from = -1; // 0:自分 1:上家 2:対面 3:下家.
        this.hais =[];
        this.naki = NAKI.INVALID;
    }

    GetFuroHai()
    {
        if(this.from ==-1){ return "";}
        else if(this.naki == NAKI.KAKAN)
        {
            return this.hais[3];            
        }
        return (this.hais[3-this.from]);
    }
    GetFuroHaiSubList()
    {
        if(this.naki===NAKI.ANKAN)
        {
            let hais_ = Object.assign([],this.hais);
            return hais_;
        }
        else
        {
            let hais_ = Object.assign([],this.hais);
            let index = 3 - this.from;
            hais_.splice(index,1);
        return hais_;
        }
    }
    static FromString(str)
    {
        let naki = new NakiData();

        if(typeof str != "string")
        {
            return naki;
        }

        if (this.IsAnkan(str))
        {
            naki.naki = NAKI.ANKAN;
            naki.from = 0;

            let hai_index = str.indexOf('a');
            let hai = str.substr(hai_index+1,2);
            // console.warn(hai);
            let res = Number(hai);
            if (!isNaN(res))
            {
                let hai_ = d_tenhou_inv[hai];
                let hai1 = hai_;
                let hai2 = hai_;

                if (hai_==='0m' || hai_==='5m')
                {
                    hai1 = '0m';
                    hai2 = '5m';
                }
                else if (hai_==='0p' || hai_==='5p')
                {
                    hai1 = '0p';
                    hai2 = '5p';
                }
                else if (hai_ ==='0s' || hai_==='5s')
                {
                    hai1 = '0s';
                    hai2 = '5s';
                }

                naki.hais.push(hai2);
                naki.hais.push(hai1);
                naki.hais.push(hai2);
                naki.hais.push(hai2);
            }
            // console.warn(naki);
        }
        else if (this.IsKakan(str))
        {
            naki.naki = NAKI.KAKAN;
            //naki.from = 3;

            let hai_index = str.indexOf('k');
            naki.from = 3 - (hai_index / 2);

            for (let i = 0; i < 4; i++)
            {
                let hai = str.substr(i*2,2);
                let res = Number(hai);
                if (!isNaN(res))
                {
                    naki.hais.push(d_tenhou_inv[hai]);
                }
            }
            // console.warn(naki);
        }
        else if (this.IsDaiminkan(str))
        {
            naki.naki = NAKI.MINKAN;
            naki.from = 3;

            let hai_index = str.indexOf('m');
            naki.from = 3 - (hai_index / 2);
            let removed = str.replace('m','');
            for (let i = 0; i < 4; i++)
            {
                let hai = removed.substr(i * 2, 2);
                let res = Number(hai);
                if (!isNaN(res))
                {
                    naki.hais.push(d_tenhou_inv[hai]);
                }
            }
            // console.warn(naki);
        }
        else if (this.IsChi(str))
        {
            // console.log(str);
            naki.naki = NAKI.CHI;
            naki.from = 3;

            let hai_index = str.indexOf('c');
            naki.from = 3 - (hai_index / 2);
            // var removed = str.splice(hai_index, 1);
            // str.splice(hai_index, 1);
            // var removed = str;
            let removed = str.replace('c','');
            // console.log(removed);
            for (let i = 0; i < 3; i++)
            {
                let hai = removed.substr(i * 2, 2);
                let res = Number(hai);
                if (!isNaN(res))
                {
                    naki.hais.push(d_tenhou_inv[hai]);
                }
            }
            // console.warn(naki);
        }
        else if (this.IsPon(str))
        {
            naki.naki = NAKI.PON;
            naki.from = 3;

            let hai_index = str.indexOf('p');
            naki.from = 3 - (hai_index / 2);
            // var removed = str.splice(hai_index, 1);
            let removed = str.replace('p','');

            for (let i = 0; i < 3; i++)
            {
                let hai = removed.substr(i * 2, 2);
                let res = Number(hai);
                if (!isNaN(res))
                {
                    naki.hais.push(d_tenhou_inv[hai]);
                }
            }
            // console.warn(naki);
        }
        return naki;
    }

    static  IsAnkan(str)
    {
        if(!isNaN(Number(str))){return false;}
        return str.indexOf('a')!=-1;
    }
    static  IsKakan( str)
    {
        if(!isNaN(Number(str))){return false;}
        return str.indexOf('k')!=-1;
    }
    static  IsDaiminkan( str)
    {
        if(!isNaN(Number(str))){return false;}
        return str.indexOf('m')!=-1;
    }
    static  IsChi(str)
    {
        if(!isNaN(Number(str))){return false;}
        // console.log(str);
        // console.log( str.indexOf('c'));
        return str.indexOf('c')!=-1;
    }
    static  IsPon( str)
    {
        if(!isNaN(Number(str))){return false;}
        return str.indexOf('p')!=-1;
    }
}

export class PlayData
{
    constructor(){
        this.haipai = [];
        this.tsumohai=[];
        this.sutehai=[];
    }
    Copy(a)
    {
        this.haipai = Object.assign([],a.haipai);
        this.tsumohai = Object.assign([],a.tsumohai);
        this.sutehai = Object.assign([],a.sutehai);
    }

    IsEnd()
    {
        return this.IsEmpty(TYPE.TSUMOHAI)&&this.IsEmpty(TYPE.SUTEHAI);
    }

    IsEmpty(type)
    {
        switch(type)
        {
            case TYPE.TSUMOHAI:
                {
                    return (this.tsumohai.length==0);                    
                }
            case TYPE.SUTEHAI:
                {
                    return (this.sutehai.length==0);                    
                }
        }
        return true;
    }

    PopCheck(type)
    {
        switch(type)
        {
            case TYPE.TSUMOHAI:
            {
                if(this.tsumohai.length>0)
                {
                    return this.tsumohai[0];
                }       
            }
            break;
            case TYPE.SUTEHAI:
                {
                    if(this.sutehai.length>0)
                    {
                        return this.sutehai[0];
                    }       
                }
                break;
        }
        return null;
    }
    Pop(type)
    {
        let check = false;
        switch(type)
        {
            case TYPE.TSUMOHAI:
            {
                if(this.tsumohai.length>0)
                {
                    let hai = this.tsumohai.shift();
                    return hai;
                }
                else
                {
                    check = true;
                }
            }
            break;
            case TYPE.SUTEHAI:
                {
                    if(this.sutehai.length>0)
                    {
                        let hai = this.sutehai.shift();
                        return hai;
                    }       
                    else
                    {
                        check = true;
                    }
                }
                break;
        }

        if(check){console.warn('Array size zero');}

        return null;
    }
}

export class ScenePlayerData
{
    constructor(){
        this.tsumo="";
        this.tehai=[];
        this.sutehai =[];
        this.naki=[];
    }
    Copy(a)
    {
        this.tsumo = a.tsumo;
        this.tehai = Object.assign([],a.tehai);
        this.sutehai = Object.assign([],a.sutehai);
        this.naki = Object.assign([],a.naki);
    }

    SetInitialDataFromPlayData(playdata)
    {
        // this.tehai = Object.assign([],playdata.haipai);
        this.tehai=[];
        this.sutehai=[];
        this.naki=[];
        this.tsumo="";

        for ( let i = 0; i < playdata.haipai.length; i++)
        {
            let h = playdata.haipai[i];
            this.tehai.push(d_tenhou_inv[String(h)]);
        }

    }

    TehaiSort()
    {
        this.tehai.sort(function(a, b) { return d_hai_sort[a] - d_hai_sort[b];});
    }
    AddTehai(hai)
    {
        this.tehai.push(hai);
        this.TehaiSort();
    }
    SetTsumo(hai)
    {
        this.tsumo = hai;
    }
    RemoveTsumo()
    {
        this.tsumo = "";
    }
    _AddNaki(naki)
    {
        this.naki.push(naki);
        // this.SetLastPlayedHai(naki.GetFuroHai());
    }
    _RemoveFuroHai(naki)
    {
        let sublist = naki.GetFuroHaiSubList();
        // console.warn(sublist);
        for ( let h in sublist)
        {
            // console.warn(sublist[h]);
            let index = this.tehai.indexOf(sublist[h]);
            // console.warn(index);
            this.tehai.splice(index,1);
        }
    }
    AddKakan(naki)
    {
        this.AddTsumoToTehai();

        let index = this.tehai.indexOf(naki.GetFuroHai());
        this.tehai.splice(index,1);
        this._AddNaki(naki);
    }
    AddAnkan(naki)
    {
        this.AddTsumoToTehai();
        this._AddNaki(naki);
        this._RemoveFuroHai(naki);
    }
    AddFuroFromOther(naki)
    {
        this._AddNaki(naki);
        this._RemoveFuroHai(naki);
    }
    AddSutehai(sutehai)
    {
        this.sutehai.push(sutehai);
    }
    AddTsumoToTehai()
    {
        if(this.tsumo==='')
        {
            console.warn('tsumo empty');
        }
        else
        {
        this.tehai.push(this.tsumo);
        this.RemoveTsumo();
        this.TehaiSort();
        }
    }
    AddNakareInfo()
    {
        let length = this.sutehai.length;
        this.sutehai[length - 1] = "N" + this.sutehai[length - 1];
    }

    Dahai(hai,furo,tsumogiri,reach)
    {
        let sutehai ="";
        if(reach)
        {
            sutehai = sutehai + 'r';
        }
        if(tsumogiri)
        {
            sutehai = sutehai + 'Q';
        }
        if(tsumogiri)
        {
            hai = this.tsumo;
        }
        sutehai = sutehai + hai;
        this.sutehai.push(sutehai);
        if(!furo)
        {
            if(!tsumogiri)
            {
                this.AddTsumoToTehai();
                let index = this.tehai.indexOf(hai);
                this.tehai.splice(index,1);
            }
            this.tsumo="";
        }
        else
        {
            let index = this.tehai.indexOf(hai);
            this.tehai.splice(index,1);
        }
    }
}

export class Scene
{
    constructor(){
        this.step = REPLAY_STEP.INVALID;
        this.step_next = REPLAY_STEP.INVALID;
        this.step_cnt = -1;
        this.next_player = -1;
        this.last_player = -1;
        this.last_played_hai ="";
        this.rest_cnt = 4*34;
        this.dora_cnt = 1;
        this.playerdatas=[new ScenePlayerData(),new ScenePlayerData(),new ScenePlayerData(),new ScenePlayerData()];
        this.reached=[false,false,false,false];
    }

    GetRestCnt()
    {
        return this.rest_cnt;
    }
    DecRestCnt()
    {
        this.rest_cnt--;
    }

    GetDoraCnt()
    {
        return this.dora_cnt;
    }
    IncDoraCnt()
    {
        this.dora_cnt++;
    }

    GetPlayerData(player)
    {
        return this.playerdatas[player];
    }
    SetLastPlayedHai(hai)
    {
        if(hai==='')
        {
            //
        }
        else if(hai===undefined)
        {
            console.warn('undefined');
        }

        this.last_played_hai = hai;
    }
    ClearLastPlayedHai()
    {
        this.last_played_hai = "";
    }
    GetLastPlayedHai()
    {
        return this.last_played_hai;
    }
    SetInitialScene(datas,next_player)
    {
        for(let i = 0; i < 4; i++)
        {
            this.playerdatas[i].SetInitialDataFromPlayData(datas[i]);
        }
        this.step = REPLAY_STEP.START;
        this.step_next = REPLAY_STEP.TSUMO;
        this.step_cnt = 0;
        this.next_player = next_player;
        this.last_player = -1;

        this.dora_cnt = 1;
        this.rest_cnt = 4*34 - 13 * 4;
    }
    Clone()
    {
        let scene = new Scene();

        scene.step = this.step;
        scene.step_next = this.step_next;
        scene.step_cnt = this.step_cnt;
        scene.next_player = this.next_player;
        scene.last_player = this.last_player;
        scene.last_played_hai = this.last_played_hai;
        scene.rest_cnt = this.rest_cnt;
        scene.dora_cnt = this.dora_cnt;
        scene.reached[0] = this.reached[0];
        scene.reached[1] = this.reached[1];
        scene.reached[2] = this.reached[2];
        scene.reached[3] = this.reached[3];

        for(let i = 0; i < 4; i ++)
        {
            scene.playerdatas[i].Copy(this.playerdatas[i]);
        }
        return scene;
    }
    IncStepCnt()
    {
        this.step_cnt++;
    }
    SetStep(step)
    {
        this.step = step;
    }
    SetStepNext(step)
    {
        this.step_next = step;
    }
    GetStepNext()
    {
        return this.step_next;
    }
    SetNextPlayer(player)
    {
        this.next_player = player;
    }
    GetNextPlayer()
    {
        return this.next_player;
    }
    SetLastPlayer(player)
    {
        this.last_player = player;
    }
    IsEnd()
    {
        return (this.step_next == REPLAY_STEP.END);
    }
}

export class Replayer
{
    constructor(){
        this.playdatas = [new PlayData(),new PlayData(),new PlayData(),new PlayData()];
        this.scene = new Scene();
        this.scenes = [];
    }

    EntryScene()
    {
        this.scenes.push(this.scene.Clone());
    }

    SetInitialScene(playdatas,next_player)
    {
        this.scene = new Scene();
        this.scenes = [];

        for (let i = 0; i < 4; i++)
        {
            this.playdatas[i].Copy(playdatas[i]);
        }
        this.scene.SetInitialScene(this.playdatas,next_player);
        this.EntryScene();
    }

    Dump()
    {
        // console.log(this.scene);        
        console.log(this.scenes);
        console.log(this.scene);
        console.log(this.playdatas);
        console.log(this.scene.GetStepNext());
    }

    IsEnd()
    {
        for(let i = 0; i < 4; i++)
        {
            if ( !this.playdatas[i].IsEnd() )
            {
                return false;
            }
        }
        return true;
    }

    Replay()
    {
        let exit = false;
        while (!exit)
        {
            switch (this.scene.GetStepNext())
            {
                case REPLAY_STEP.TSUMO:
                    exit = this.Replay_tsumo(this.scene.GetNextPlayer());
                    break;
                case REPLAY_STEP.KAN:
                    exit = this.Replay_kan(this.scene.GetNextPlayer());
                    break;
                case REPLAY_STEP.DAHAI:
                    exit = this.Replay_dahai(this.scene.GetNextPlayer());
                    break;
                case REPLAY_STEP.NAKI:
                    exit = this.Replay_naki(this.scene.GetNextPlayer());
                    break;

                default:
                    exit = true;
                    break;
            }
        }

        if (this.scene.GetStepNext() != REPLAY_STEP.END)
        {
//            m_scenelist.Add(scene);
            this.EntryScene();

//            DumpScene();
//            playDataManager.Dump();

            if (this.IsEnd())
            {
                this.scene.SetStepNext( REPLAY_STEP.END );
            }
        }
    }

    Replay_All()
    {
        for (let cnt = 0; cnt < 200; cnt++)
        {
//            console.log(cnt);
            this.Replay();
            // this.Dump();
            if(this.scene.GetStepNext()==REPLAY_STEP.END)
            {
                break;
            }
        }
    }

    Replay_tsumo(player)
    {
        let tsumohai = this.playdatas[player].Pop(TYPE.TSUMOHAI);

        let number = Number(tsumohai);
        if (!isNaN(number))
        {
            this.scene.GetPlayerData(player).SetTsumo(d_tenhou_inv[tsumohai]);
            this.scene.IncStepCnt();
            this.scene.SetLastPlayedHai(d_tenhou_inv[tsumohai]);

            this.scene.DecRestCnt();
    }
        else
        {
            // Debug.LogError("error");
        }
        this.scene.SetStepNext(REPLAY_STEP.KAN);
        this.scene.SetStep(REPLAY_STEP.TSUMO);
        this.scene.SetLastPlayer(player);

        return true;
    }

    Replay_kan(player)
    {
        if (this.CheckAnkan(player))
        {
            this.scene.SetStep(REPLAY_STEP.KAN);
            this.scene.SetStepNext(REPLAY_STEP.TSUMO);
            this.scene.IncStepCnt();
            this.scene.SetLastPlayer(player);

            let sutehai = this.playdatas[player].Pop(TYPE.SUTEHAI);
            let naki = NakiData.FromString(sutehai);

            this.scene.GetPlayerData(player).AddAnkan(naki);
            // this.scene.SetLastPlayedHai(naki.GetFuroHai());
            this.scene.ClearLastPlayedHai();

            this.scene.IncDoraCnt();

            return true;
        }
        else if (this.CheckKakan(player))
        {
            this.scene.SetStep(REPLAY_STEP.KAN);
            this.scene.SetStepNext(REPLAY_STEP.TSUMO);
            this.scene.IncStepCnt();
            this.scene.SetLastPlayer(player);
    

            let sutehai = this.playdatas[player].Pop(TYPE.SUTEHAI);
            let naki = NakiData.FromString(sutehai);
            
            this.scene.GetPlayerData(player).AddKakan(naki);
            this.scene.SetLastPlayedHai(naki.GetFuroHai());

            return true;
        }
        else
        {
            this.scene.SetStepNext(REPLAY_STEP.DAHAI);
            return false;
        }
    }

    IsReach(str)
    {
        if(!isNaN(Number(str)))
        {
            return false;
        }

        return str.indexOf('r')!=-1;
    }

    Replay_dahai(player)
    {
        let sutehai = this.playdatas[player].Pop(TYPE.SUTEHAI);
        if(sutehai===null)
        {
            console.warn(this.scene.step);
        }
        let number = Number(sutehai);
        
        let reach = this.IsReach(sutehai);

        let hai = reach ? sutehai.substr(1,sutehai.length-1) : sutehai;
        let hai_cnv = d_tenhou_inv[hai];

        let tsumogiri = hai_cnv==='Q';
        
        let furo = this.scene.step == REPLAY_STEP.NAKI;

        // if(false)
        // {
        //     let str ='=dahai_info='; 
        //     str += '\n';
        //     str += 'step_cnt:'+String(this.scene.step_cnt);
        //     str += '\n';
        //     str += 'player:'+String(player);
        //     str += '\n';
        //     str += 'sutehai:'+String(sutehai);
        //     str += '\n';
        //     str += 'hai:'+String(hai);
        //     str += '\n';
        //     str += 'hai_cnv:'+String(hai_cnv);
        //     str += '\n';
        //     str += 'tsumogiri:'+ String(tsumogiri);
        //     str += '\n';
        //     str += 'furo:'+ String(furo);
        //     str += '\n';
        //     str += 'reach:'+ String(reach);
        //     console.warn(str);
        // }
        this.scene.GetPlayerData(player).Dahai(hai_cnv,furo,tsumogiri,reach);
        this.scene.SetLastPlayer(player);
        
        if(reach)
        {
            this.scene.reached[player] = true;
        }

        if(hai_cnv==undefined)
        {
            console.warn(this.scene.step_cnt);
            console.warn(sutehai);
            console.warn(hai);
        }
        if(hai_cnv==='Q')
        {
            //
        }
        else
        {
            // そのまま.
            this.scene.SetLastPlayedHai(hai_cnv);
        }
        this.scene.IncStepCnt();

        this.scene.SetStepNext(REPLAY_STEP.NAKI);
        this.scene.SetStep(REPLAY_STEP.DAHAI);

        return true;
    }

    Replay_naki(player)
    {
        let chi = -1;
        let pon = -1;
        let minkan = -1;
        for (let i = 1; i < 4; i++)
        {
            let check_player = (player + i) % 4;
            let tsumohai = this.playdatas[check_player].PopCheck(TYPE.TSUMOHAI);

            if (tsumohai == null)
            {
                continue;
            }

            let naki_check = NakiData.FromString(tsumohai);
            // そもそも副露じゃない.
            if (naki_check.naki == NAKI.INVALID)
            {
                continue;
            }
            // 鳴いた牌が一致.
            if (naki_check.GetFuroHai() != this.scene.GetLastPlayedHai())
            {
                continue;
            }
            // 誰から鳴いたか？.
            if (naki_check.from != 3 - i + 1)
            {
                continue;
            }
            switch (naki_check.naki)
            {
                case NAKI.CHI:
                    chi = i;
                    break;
                case NAKI.PON:
                    pon = i;
                    break;
                case NAKI.MINKAN:
                    minkan = i;
                    break;
            }
        }

        // 副露はなかった.
        if (chi == -1 && pon == -1 && minkan == -1)
        {
            this.scene.SetStepNext(REPLAY_STEP.TSUMO);
            this.scene.SetNextPlayer((player + 1) % 4);
            return false;
        }

        let check_index = -1;
        if (pon != -1) { check_index = pon; }
        else if (minkan != -1) { check_index = minkan; }
        else if (chi != -1) { check_index = chi; }

        if (check_index == -1)
        {
            // Debug.LogError("furo error");
            this.scene.SetStepNext(REPLAY_STEP.TSUMO);
            this.scene.SetNextPlayer((player + 1) % 4);
            return false;
        }

        let target_player = (player + check_index) % 4;
        let target_naki = this.playdatas[target_player].Pop(TYPE.TSUMOHAI);

        { 
            let naki = NakiData.FromString(target_naki);

            this.scene.SetStep(REPLAY_STEP.NAKI);
            if(naki.naki == NAKI.MINKAN)
            {
                this.scene.SetStepNext(REPLAY_STEP.TSUMO);
            }
            else
            {
                this.scene.SetStepNext(REPLAY_STEP.DAHAI);
            }
            this.scene.SetNextPlayer(target_player);
            this.scene.IncStepCnt();
            this.scene.SetLastPlayer(target_player);

            this.scene.GetPlayerData(target_player).AddFuroFromOther(naki);
            this.scene.SetLastPlayedHai(naki.GetFuroHai());
            // 鳴かれた牌に情報をたす.
            let ind = (target_player + naki.from)%4;
            this.scene.GetPlayerData(ind).AddNakareInfo();
        }

        return true;
    }


    CheckAnkan(player)
    {
        let sutehai = this.playdatas[player].PopCheck(TYPE.SUTEHAI);
        let check = Number(sutehai);
        // 数値に変換できないときは、何か文字を含んでいる.
        // つまり暗KAN.
        // 加KANもあるけど、一旦保留.
        if (isNaN(check))
        {
            if (sutehai.indexOf('r')!=-1)
            {
                // 立直.
                return false;
            }
            else if (sutehai.indexOf('a')!=-1)
            {
                return true;
            }
            else
            {
                return false;
            }
        }

        return false;
    }

    CheckKakan(player)
    {
        let sutehai = this.playdatas[player].PopCheck(TYPE.SUTEHAI);
        let check = Number(sutehai);
        // 数値に変換できないときは、何か文字を含んでいる.
        // つまり暗KAN.
        // 加KANもあるけど、一旦保留.
        if (isNaN(check))
        {
            if (sutehai.indexOf('r')!=-1)
            {
                // 立直.
                return false;
            }
            else if (sutehai.indexOf('k')!=-1)
            {
                return true;
            }
            else
            {
                return false;
            }
        }

        return false;
    }

}

export class FromTenhou
{
    constructor(){
        this.m_data = new TenhouFormat();
        this.m_replayer = new Replayer();
    }
    SetJson(json)
    {
        this.m_data.Set(json);

        let a = this.m_data.GetPlayDatas();
//        console.log(a);
        this.m_replayer.SetInitialScene(a,this.m_data.GetStartPlayer());
    }
    Dump()
    {
        this.m_data.Dump();
        this.m_replayer.Dump();
    }
    Replay()
    {
        this.m_replayer.Replay();
    }
    Replay_All()
    {
        this.m_replayer.Replay_All();
    }

    GetInfo()
    {
        return this.m_data.info;
    }

    GetTehai(player)
    {
        return this.GetPlayerData(player).tehai;
    }
    GetSutehai(player)
    {
        return this.GetPlayerData(player).sutehai;
    }
    GetNaki(player)
    {
        return this.GetPlayerData(player).naki;
    }
    GetTsumo(player)
    {
        return this.GetPlayerData(player).tsumo;
    }
    GetPlayerData(player)
    {
        return this.m_replayer.scene.GetPlayerData(player);
    }

    GetNextSkipScene(scene_index, player, isLoop)
    {
        let scene_index_ = scene_index;
        if(scene_index===-1)
        {
            scene_index_ = this.m_replayer.scenes.length-1;
        }
        for( let ind = scene_index_ + 1; ind < this.m_replayer.scenes.length;ind++)
        {
            if ( this.m_replayer.scenes[ind].last_player == player )
            {
                return ind;
            }
        }
        if(isLoop)
        {
            return 0;
        }
        else
        {
            return scene_index;
        }
    }
    GetPrevSkipScene(scene_index, player, isLoop)
    {
        let scene_index_ = scene_index;
        if(scene_index===-1)
        {
            scene_index_ = this.m_replayer.scenes.length-1;
        }
        for( let ind = scene_index_ - 1; ind >= 0;ind--)
        {
            if ( this.m_replayer.scenes[ind].last_player == player )
            {
                return ind;
            }
        }
        if(isLoop)
        {
            return this.m_replayer.scenes.length-1;
        }
        else
        {
            return scene_index;
        }
    }

    GetRestCnt()
    {
        return this.m_replayer.scene.GetRestCnt();
    }

    GetRestCnt_s(scene_index)
    {
        let scene_index_ = scene_index;
        if(scene_index===-1)
        {
            scene_index_ = this.m_replayer.scenes.length-1;
        }

        return this.m_replayer.scenes[scene_index_].GetRestCnt();
    }

    GetCurrent_s(scene_index)
    {
        let scene_index_ = scene_index;
        if(scene_index===-1)
        {
            scene_index_ = this.m_replayer.scenes.length-1;
        }

        return this.m_replayer.scenes[scene_index_].last_player;
    }

    GetDoraCnt_s(scene_index)
    {
        let scene_index_ = scene_index;
        if(scene_index===-1)
        {
            scene_index_ = this.m_replayer.scenes.length-1;
        }
//        console.log(scene_index_);
        // console.log(this.m_replayer.scenes[scene_index_]);

        return this.m_replayer.scenes[scene_index_].GetDoraCnt();
    }


    GetTehai_s(player,scene_index)
    {
        return this.GetPlayerData_s(player,scene_index).tehai;
    }
    GetSutehai_s(player,scene_index)
    {
        return this.GetPlayerData_s(player,scene_index).sutehai;
    }
    GetNaki_s(player,scene_index)
    {
        return this.GetPlayerData_s(player,scene_index).naki;
    }
    GetTsumo_s(player,scene_index)
    {
        return this.GetPlayerData_s(player,scene_index).tsumo;
    }
    GetPlayerData_s(player,scene_index)
    {
        let scene_index_ = scene_index;
        if(scene_index===-1)
        {
            scene_index_ = this.m_replayer.scenes.length-1;
        }

        return this.m_replayer.scenes[scene_index_].GetPlayerData(player);
    }

    GetReached_s(scene_index)
    {
        let scene_index_ = scene_index;
        if(scene_index===-1)
        {
            scene_index_ = this.m_replayer.scenes.length-1;
        }

        return this.m_replayer.scenes[scene_index_].reached;
    }

    GetSceneLength()
    {
        return this.m_replayer.scenes.length;
    }

    GetStuts()
    {
        return this.m_data.info.stuts;
    }
    GetDoras_s(scene_index)
    {
        let cnt = 0;
        let ret = [];
        var doras = this.m_data.info.doras;
        for (var i = 0; i < doras.length; i++)
        {
            ret.push(d_tenhou_inv[String(doras[i])]);
            cnt++;
            if(this.GetDoraCnt_s(scene_index)==cnt)
            {
//                console.warn(cnt);
//                console.warn(this.GetDoraCnt_s(scene_index));
                break;
            }
        }
        for (var j = cnt; j<5; j++)
        {
            ret.push("0z");
        }
        return ret;
    }
    GetNames()
    {
        return this.m_data.name;
    }
}