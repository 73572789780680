import {
    PlayData,
    Game,
    GameInfo,
    NakiData,
} from "./tenhou_format"
import {
    Scene,
    SceneOperator,
    CloneScene,
    MakeInitialScene,
} from "./tenhou_scene"
import {
    REPLAY_STEP,
    TYPE,
    NAKI,
} from "./tenhou_types"
import {
    d_tenhou_inv,
} from "./tenhou_dic"


export class Replayer
{
    playdatas:PlayData[];
    scene:Scene;
    scenes:Scene[];
    sceneOperator:SceneOperator;

    constructor(){
        this.playdatas = [new PlayData(),new PlayData(),new PlayData(),new PlayData()];
        this.scene = new Scene();
        this.scenes = [];
        this.sceneOperator = new SceneOperator(this.scene);
    }

    EntryScene()
    {
        this.scenes.push(CloneScene(this.scene));
    }

    SetInitialScene(playdatas:PlayData[],first_player:number)
    {
        this.scene = MakeInitialScene(playdatas,first_player);
        this.scenes = [];
        this.sceneOperator.SetScene(this.scene);

        for (let i = 0; i < 4; i++)
        {
            this.playdatas[i].Copy(playdatas[i]);
        }
        this.EntryScene();
    }

    Dump():void
    {
        // console.log(this.scene);        
        console.log(this.scenes);
        console.log(this.scene);
        console.log(this.playdatas);
        console.log(this.scene.GetStepNext());
    }

    IsEnd():boolean
    {
        for(let i = 0; i < 4; i++)
        {
            if ( !this.playdatas[i].IsEnd() )
            {
                return false;
            }
        }
        return true;
    }

    Replay():void
    {
        let exit = false;
        let error = false;
        while (!exit)
        {
            switch (this.scene.GetStepNext())
            {
                case REPLAY_STEP.TSUMO:
                    {
                        const tsumo_res = this.Replay_tsumo(this.scene.GetNextPlayer());
                        exit = tsumo_res.exit;
                        error = tsumo_res.error;
                    }
                    break;
                case REPLAY_STEP.KAN:
                    exit = this.Replay_kan(this.scene.GetNextPlayer());
                    break;
                case REPLAY_STEP.DAHAI:
                    exit = this.Replay_dahai(this.scene.GetNextPlayer());
                    break;
                case REPLAY_STEP.NAKI:
                    exit = this.Replay_naki(this.scene.GetNextPlayer());
                    break;

                default:
                    exit = true;
                    break;
            }
        }

        if (this.scene.GetStepNext() != REPLAY_STEP.END)
        {
//            m_scenelist.Add(scene);
            this.EntryScene();

//            DumpScene();
//            playDataManager.Dump();

            if (this.IsEnd())
            {
                this.sceneOperator.SetStepNext( REPLAY_STEP.END );
            }
        }
    }

    Replay_All():void
    {
        for (let cnt = 0; cnt < 200; cnt++)
        {
//            console.log(cnt);
            this.Replay();
            // this.Dump();
            if(this.scene.GetStepNext()==REPLAY_STEP.END)
            {
                break;
            }
        }
    }

    // 2番目はerror
    private Replay_tsumo(player:number):{exit:boolean,error:boolean}
    {
        const tsumohai = this.playdatas[player].Pop(TYPE.TSUMOHAI);
        if(tsumohai==null){ return {exit:false,error:true};}

        const number = Number(tsumohai);
        if (!isNaN(number))
        {
            this.scene.GetPlayerData(player).SetTsumo(d_tenhou_inv[tsumohai]);
            this.sceneOperator.IncStepCnt();
            this.sceneOperator.SetLastPlayedHai(d_tenhou_inv[tsumohai]);

            this.sceneOperator.DecRestCnt();
        }
        else
        {
            // Debug.LogError("error");
        }
        this.sceneOperator.SetStepNext(REPLAY_STEP.KAN);
        this.sceneOperator.SetStep(REPLAY_STEP.TSUMO);
        this.sceneOperator.SetLastPlayer(player);

        return {exit:true,error:false};
    }

    private Replay_kan(player:number):boolean
    {
        if (this.CheckAnkan(player))
        {
            this.sceneOperator.SetStep(REPLAY_STEP.KAN);
            this.sceneOperator.SetStepNext(REPLAY_STEP.TSUMO);
            this.sceneOperator.IncStepCnt();
            this.sceneOperator.SetLastPlayer(player);

            const sutehai = this.playdatas[player].Pop(TYPE.SUTEHAI);
            if(sutehai==null){return false;}
            const naki = NakiData.FromString(sutehai);

            this.scene.GetPlayerData(player).AddAnkan(naki);
            // this.scene.SetLastPlayedHai(naki.GetFuroHai());
            this.sceneOperator.ClearLastPlayedHai();

            this.sceneOperator.IncDoraCnt();

            return true;
        }
        else if (this.CheckKakan(player))
        {
            this.sceneOperator.SetStep(REPLAY_STEP.KAN);
            this.sceneOperator.SetStepNext(REPLAY_STEP.TSUMO);
            this.sceneOperator.IncStepCnt();
            this.sceneOperator.SetLastPlayer(player);
    

            const sutehai = this.playdatas[player].Pop(TYPE.SUTEHAI);
            if(sutehai==null){return false;}
            const naki = NakiData.FromString(sutehai);
            
            this.scene.GetPlayerData(player).AddKakan(naki);
            this.sceneOperator.SetLastPlayedHai(naki.GetFuroHai());

            return true;
        }
        else
        {
            this.sceneOperator.SetStepNext(REPLAY_STEP.DAHAI);
            return false;
        }
    }

    private IsReach(str:string):boolean
    {
        if(!isNaN(Number(str)))
        {
            return false;
        }

        return str.indexOf('r')!=-1;
    }

    private Replay_dahai(player:number):boolean
    {
        const sutehai = this.playdatas[player].Pop(TYPE.SUTEHAI);
        if(sutehai===null)
        {
            console.warn(this.scene.step);
            return false;
        }
       
        const reach = this.IsReach(sutehai);

        const hai = reach ? sutehai.substr(1,sutehai.length-1) : sutehai;
        const hai_cnv = d_tenhou_inv[hai];

        const tsumogiri:boolean = hai_cnv==='Q';
        
        const furo:boolean = this.scene.step == REPLAY_STEP.NAKI;

        this.sceneOperator.Dahai(player,hai_cnv,furo,tsumogiri,reach);
        this.sceneOperator.SetLastPlayer(player);

        if(hai_cnv==undefined)
        {
            console.warn(this.scene.step_cnt);
            console.warn(sutehai);
            console.warn(hai);
        }
        if(hai_cnv==='Q')
        {
            //
        }
        else
        {
            // そのまま.
            this.sceneOperator.SetLastPlayedHai(hai_cnv);
        }
        this.sceneOperator.IncStepCnt();

        this.sceneOperator.SetStepNext(REPLAY_STEP.NAKI);
        this.sceneOperator.SetStep(REPLAY_STEP.DAHAI);

        return true;
    }

    private Replay_naki(player:number):boolean
    {
        let chi = -1;
        let pon = -1;
        let minkan = -1;
        for (let i = 1; i < 4; i++)
        {
            const check_player = (player + i) % 4;
            const tsumohai = this.playdatas[check_player].PopCheck(TYPE.TSUMOHAI);

            if (tsumohai == null)
            {
                continue;
            }

            const naki_check = NakiData.FromString(tsumohai);
            // そもそも副露じゃない.
            if (naki_check.naki == NAKI.INVALID)
            {
                continue;
            }
            // 鳴いた牌が一致.
            if (naki_check.GetFuroHai() != this.scene.GetLastPlayedHai())
            {
                continue;
            }
            // 誰から鳴いたか？.
            if (naki_check.from != 3 - i + 1)
            {
                continue;
            }
            switch (naki_check.naki)
            {
                case NAKI.CHI:
                    chi = i;
                    break;
                case NAKI.PON:
                    pon = i;
                    break;
                case NAKI.MINKAN:
                    minkan = i;
                    break;
            }
        }

        // 副露はなかった.
        if (chi == -1 && pon == -1 && minkan == -1)
        {
            this.sceneOperator.SetStepNext(REPLAY_STEP.TSUMO);
            this.sceneOperator.SetNextPlayer((player + 1) % 4);
            return false;
        }

        let check_index = -1;
        if (pon != -1) { check_index = pon; }
        else if (minkan != -1) { check_index = minkan; }
        else if (chi != -1) { check_index = chi; }

        if (check_index == -1)
        {
            // Debug.LogError("furo error");
            this.sceneOperator.SetStepNext(REPLAY_STEP.TSUMO);
            this.sceneOperator.SetNextPlayer((player + 1) % 4);
            return false;
        }

        const target_player = (player + check_index) % 4;
        const target_naki = this.playdatas[target_player].Pop(TYPE.TSUMOHAI);
        if(target_naki==null){return false;}

        { 
            const naki = NakiData.FromString(target_naki);

            this.sceneOperator.SetStep(REPLAY_STEP.NAKI);
            if(naki.naki == NAKI.MINKAN)
            {
                this.sceneOperator.SetStepNext(REPLAY_STEP.TSUMO);
            }
            else
            {
                this.sceneOperator.SetStepNext(REPLAY_STEP.DAHAI);
            }
            this.sceneOperator.SetNextPlayer(target_player);
            this.sceneOperator.IncStepCnt();
            this.sceneOperator.SetLastPlayer(target_player);

            this.sceneOperator.AddFuroFromOther(target_player,naki);
            this.sceneOperator.SetLastPlayedHai(naki.GetFuroHai()); // TODO:ここも治す.
            // 鳴かれた牌に情報をたす.
            const ind = (target_player + naki.from)%4;
            this.sceneOperator.AddNakareInfo(ind);
        }

        return true;
    }


    private CheckAnkan(player:number):boolean
    {
        const sutehai = this.playdatas[player].PopCheck(TYPE.SUTEHAI);
        if(sutehai==null){return false;}

        const check = Number(sutehai);
        // 数値に変換できないときは、何か文字を含んでいる.
        // つまり暗KAN.
        // 加KANもあるけど、一旦保留.
        if (isNaN(check))
        {
            if (sutehai.indexOf('r')!=-1)
            {
                // 立直.
                return false;
            }
            else if (sutehai.indexOf('a')!=-1)
            {
                return true;
            }
            else
            {
                return false;
            }
        }

        return false;
    }

    private CheckKakan(player:number):boolean
    {
        const sutehai = this.playdatas[player].PopCheck(TYPE.SUTEHAI);
        if(sutehai==null){return false;}
        const check = Number(sutehai);
        // 数値に変換できないときは、何か文字を含んでいる.
        // つまり暗KAN.
        // 加KANもあるけど、一旦保留.
        if (isNaN(check))
        {
            if (sutehai.indexOf('r')!=-1)
            {
                // 立直.
                return false;
            }
            else if (sutehai.indexOf('k')!=-1)
            {
                return true;
            }
            else
            {
                return false;
            }
        }

        return false;
    }

}
