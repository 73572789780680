import { 
    getDatabase,
    ref,
    set,
    push,
    remove,
    child,
    get,
    limitToFirst,
    query,
    onValue,
    startAt,
    endAt,
    orderByChild,
} from "firebase/database";
import { defineStore } from 'pinia';

import {
    useAuthStore
} from "@/stores/auth"

import {
    getTime
} from "@/lib/util"

import {
    Scene
} from "@/lib/tenhou/tenhou_scene"

export interface PaifuData{
    id:string,
    paifu:any,
}
interface State{
    paifuDataList:Map<string,any>;
    isLoading:boolean;
    isKickedLoading:boolean;
}

export const usePaifusStore = defineStore({
    id: 'paifus',
    state: ():State => {
        return {
            paifuDataList: new Map<string,any>(),
            isLoading:true,
            isKickedLoading:false,
        };
  },
  getters: {
    // isLoading: (state):boolean => state.isLoading,
    getPaifus: (state):Map<string,any> => state.paifuDataList,
    getPaifu:(state)=>{
        return (paifuId:string)=>{
            if(!state.paifuDataList.has(paifuId)){
                return null;
            }
            else{
                const paifudata = state.paifuDataList.get(paifuId);
                return paifudata;
            }
        };
    },
    getPaifusByUser: (state) =>{
        return (user:string)=>{
            const map = new Map<string,any>();
            state.paifuDataList.forEach((value,key)=>{
                map.set(key,value);
            });
            return map;
        };
    }
  },
  actions: {
    async recievePaifuDatas() {
        try {
            const database = getDatabase();
        
            const twoRef = query(
                ref(database,'paifus'),
                limitToFirst(10),
                // orderByChild('time')
            );

            this.isKickedLoading = true;
            onValue(twoRef,(snapshot)=>{
                // console.log(snapshot.val());
                this.paifuDataList.clear();

                snapshot
                .forEach((child)=>{
                //   console.log(child.val());
                //   console.log(child.key);
                  this.paifuDataList.set(child.key as string,child.val());
                });
                // console.log(this.paifuDataList);
                this.isLoading = false;
                this.isKickedLoading = false;
        });
        }catch(err){
            if(err instanceof Error) {
                // const errorStore = useErrorStore();
                // errorStore.setError(err);
                console.log(err);
                return;
            }
            this.isLoading = false;
            this.isKickedLoading = false;
            throw err;
        }
    },
    async postPaifu( 
        arg={
             paifu:String,
             tags:String,
             title:String,
             comment:String,
             config:String,
             scene:String
        },
    ):Promise<boolean>{
        const auth = useAuthStore();
        if(auth.isLoggedIn)
        {
            const user = auth.currentUser;
            if(user==null){
                return false;
            }
            const database = getDatabase();
            const path = 'paifus/';
            const Ref = ref(database,path);
            push(
                Ref,
                {
                    paifu:arg.paifu,
                    title:arg.title,
                    tags:arg.tags,
                    time:getTime(),
                    user:user.uid,
                    comment:arg.comment,
                    config:arg.config,
                    scene:arg.scene
                }
            ).then((result)=>{
                // console.log( "user entered", result);
                return true;
            });
        }
        else
        {
            // console.log("user not loggedIn");
            return false;
        }
        return false;
    },
    async deletePaifu( 
        paifuId:string,
        // onSucess:(()=>void)
    ):Promise<boolean>{
        const auth = useAuthStore();
        if(auth.isLoggedIn)
        {
            const user = auth.currentUser;
            if(user==null){
                return false;
            }
            const database = getDatabase();
            const path = 'paifus/' + paifuId;
            const Ref = ref(database,path);
            remove(
               Ref
            ).then((result)=>{
                console.log( "delete ", result);
                return true;
            });
        }
        else
        {
            console.log("user not loggedIn");
            return false;
        }
        return false;
    }
  },
});