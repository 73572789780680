import {
    getDatabase,
    ref,
    set,
    push,
    child,
    get,
    limitToFirst,
    query,
    onValue,
    startAt,
    endAt
} from "firebase/database";
import { defineStore } from 'pinia';
import { useAuthStore} from "@/stores/auth"
import {
    getTime
} from "@/lib/util"
interface State{
    userDataList:Map<string,any>;
    userDataListByUserId:Map<string,any>;
    isLoading:boolean;
    isKickedLoading:boolean;
}

export const useUsersStore = defineStore({
    id: 'users',
    state: ():State => {
        return {
            userDataList: new Map<string,any>(),
            userDataListByUserId: new Map<string,any>(),
            isLoading:true,
            isKickedLoading:false,
        };
  },
  getters: {
    // isLoading: (state):boolean => state.isLoading,
    getusers: (state):Map<string,any> => state.userDataList,
    getUser:(state)=>{
        return (userId:string)=>{
            return state.userDataListByUserId.get(userId);
        };
    }
  },
  actions: {
    async recieveUserDatas() {
        try {
            const database = getDatabase();
        
            const twoRef = query(
                ref(database,'users'),
                limitToFirst(10)
            );

            this.isKickedLoading = true;
            onValue(twoRef,(snapshot)=>{
                // console.log("userDatas",snapshot.val());
                this.userDataList.clear();

                snapshot
                .forEach((child)=>{
                //   console.log("receive",child.key);
                  this.userDataList.set(child.key as string,child.val());
                  this.userDataListByUserId.set(child.val()['base']['uid'],child.val());
                });
                // console.log("userDataList", this.userDataList);
                // console.log("userDataListByUserId",this.userDataListByUserId);
                this.isLoading = false;
                this.isKickedLoading = false;
        });
        }catch(err){
            if(err instanceof Error) {
                // const errorStore = useErrorStore();
                // errorStore.setError(err);
                console.log(err);
                return;
            }
            this.isLoading = false;
            this.isKickedLoading = false;
            throw err;
        }
    },
    async postAdditionalInfo( 
        arg={
             udemae:String,
             comment:String,
             nickname:String,
        }
    ):Promise<boolean>{
        const auth = useAuthStore();
        if(auth.isLoggedIn)
        {
            const user = auth.currentUser;
            if(user==null){
                return false;
            }
            const database = getDatabase();
            // const path = 'comments/' + arg.paifuId + '/';
            const path = 'users/' + user.uid + '/add';
            const Ref = ref(database,path);
            set(
                Ref,
                {
                    nickname:arg.nickname,
                    udemae:arg.udemae,
                    comment:arg.comment,
                    time:getTime(),
                }
            ).then((result)=>{
                console.log( "user entered", result);
                return true;
            });
        }
        else
        {
            console.log("user not loggedIn");
            return false;
        }
        return false;
    },
  },
});