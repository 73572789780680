export const d_tenhou:{[id:string]:number } = 
{
    '0m':51,    '1m':11,    '2m':12,    '3m':13,    '4m':14,  '5m':15,  '6m':16,  '7m':17,  '8m':18,    '9m':19,
    '0p':52,    '1p':21,    '2p':22,    '3p':23,    '4p':24,  '5p':25,  '6p':26,  '7p':27,  '8p':28,    '9p':29,
    '0s':53,    '1s':31,    '2s':32,    '3s':33,    '4s':34,  '5s':35,  '6s':36,  '7s':37,  '8s':38,    '9s':39,
                '1z':41,    '2z':42,    '3z':43,    '4z':44,  '5z':45,  '6z':46,  '7z':47,  'E':0,      'Q':60
};

export const d_tenhou_inv:{[id:string]:string} =
{
    '51':'0m',      '11':'1m',    '12':'2m',    '13':'3m',    '14':'4m',  '15':'5m',  '16':'6m',  '17':'7m',  '18':'8m',    '19':'9m',
    '52':'0p',      '21':'1p',    '22':'2p',    '23':'3p',    '24':'4p',  '25':'5p',  '26':'6p',  '27':'7p',  '28':'8p',    '29':'9p',
    '53':'0s',      '31':'1s',    '32':'2s',    '33':'3s',    '34':'4s',  '35':'5s',  '36':'6s',  '37':'7s',  '38':'8s',    '39':'9s',
                    '41':'1z',    '42':'2z',    '43':'3z',    '44':'4z',  '45':'5z',  '46':'6z',  '47':'7z',  '0' :'E' ,    '60':'Q' 
};

export const d_hai_sort:{[id:string]:number} =
{
    '0m':15,    '1m':11,    '2m':12,    '3m':13,    '4m':14,  '5m':15,  '6m':16,  '7m':17,  '8m':18,    '9m':19,
    '0p':25,    '1p':21,    '2p':22,    '3p':23,    '4p':24,  '5p':25,  '6p':26,  '7p':27,  '8p':28,    '9p':29,
    '0s':35,    '1s':31,    '2s':32,    '3s':33,    '4s':34,  '5s':35,  '6s':36,  '7s':37,  '8s':38,    '9s':39,
                '1z':41,    '2z':42,    '3z':43,    '4z':44,  '5z':45,  '6z':46,  '7z':47,
};