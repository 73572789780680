import { marked } from "marked"
import DOMPurify from 'dompurify'

import { useResourcesStore } from "@/stores/resources"



export const sanitizer2 = (_msg) =>{
    const message = _msg;
    // const home_str = "http://localhost:8080/";
    const home_str = "https://webtest-d01da.web.app/"
    if(message.length > 0)
    {
        const resourcesStore = useResourcesStore();

        const imagepaths = resourcesStore.getpaths();
        const imagepathsArray = Array.from(imagepaths.keys());
        // console.log(imagepathsArray);
        let tikan = message;
        imagepathsArray.forEach(function(key){
            // Object.keys(imagepaths).forEach(function(key){
            const path = imagepaths.get(key);
            const key_ = "\\[" + key + "\\]";
            const pattern = new RegExp(key_,"g");
            const replace_str = "![](" + home_str + path + ")";
            // const replace_str = "<img src=" + "\""+ home_str + path + "\" " + "width=" + "\""+ "18" + "\"" + ">";
            
            // console.log( replace_str );
            tikan = tikan.replace(pattern,replace_str);
        });
        
        const marked_ = marked(tikan);        
        // console.log("tikan",tikan);
        // const sanitized = DOMPurify.sanitize(marked_);
        let sanitized = DOMPurify.sanitize(marked_);

        return sanitized;
    }
    return "";
}

export const sanitizer = (_msg,sceneLink=true) =>{
    let sanitized = sanitizer2(_msg);
    {
        // const key_ = "\\[([\\d])\\]";
        const key_ = "\\[([0-9]{1,3})\\]";
        const pattern = new RegExp(key_,"g");
        if(sceneLink)
        {
            sanitized = sanitized.replace(pattern,
                // '<a v-on:mouseover=\'mouseover($1)\'>##$1</a>'
                // "<router-link v-bind:to=\"{name: 'home'}\">##$1</Router-Link>"
                "<a href=\"/scene/$1\" style=\"background:#00ff00\">#$1</a>"
            );
        }
        else
        {
            sanitized = sanitized.replace(pattern,
                // '<a v-on:mouseover=\'mouseover($1)\'>##$1</a>'
                // "<router-link v-bind:to=\"{name: 'home'}\">##$1</Router-Link>"
                "#$1"
            );
        }
    }
    return sanitized;
}