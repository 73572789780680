import { Scene } from "./tenhou_scene";
import {
    TenhouFormat,
} from "./tenhou_format"
import {
    Replayer,
} from "./tenhou_replayer"

export class SceneInfoPresenter
{
    m_data:TenhouFormat;
    scenes:Scene[];
    valid:boolean;

    IsValidScenes():boolean
    {
        return (this.scenes.length>0);
    }

    constructor(){
        this.m_data = new TenhouFormat();
        this.scenes = [];
        this.valid = false;
    }
 
    SetData(json:string,scenes:Scene[]):void
    {
        this.m_data.Set(json);
        this.scenes = scenes;
        this.valid = true;
    }

    GetScene        = (index:number):Scene => this.scenes[index];
    LastSceneIndex  = ():number => this.scenes.length -1;
    GetStuts        = ()=>this.m_data.info.stuts;

    GetNextSceneIndex(index:number):number{
        let ret = index +1;
        if(ret > this.LastSceneIndex()){ ret = 0;}
        return ret;
    }
    GetPrevSceneIndex(index:number):number{
        let ret = index -1;
        if(ret < 0){ ret = this.LastSceneIndex();}
        return ret;
    }

    GetNextSkipSceneIndex(index:number,view:number):number{
        let ret = index;
       
        for( let ind = index + 1; ind < this.scenes.length;ind++)
        {
            if ( this.scenes[ind].last_player == view )
            {
               ret = ind;
               break;
            }
        }
        return ret;
    }

    GetPrevSkipSceneIndex(index:number,view:number):number{
        let ret = index;
       
        for( let ind = index - 1; ind >= 0;ind--)
        {
            if ( this.scenes[ind].last_player == view )
            {
               ret = ind;
               break;
            }
        }
        return ret;
    }
}

export function MakeReplayer(json:string):SceneInfoPresenter
{
    const m_data = new TenhouFormat();
    const m_replayer = new Replayer();
    m_data.Set(json);
    const a = m_data.GetPlayDatas();
    m_replayer.SetInitialScene(a,m_data.GetStartPlayer());
    m_replayer.Replay_All();

    const ret = new SceneInfoPresenter();
    ret.SetData(json,m_replayer.scenes);
    return ret;
}
