import { 
    getAuth,
    signInWithPopup, 
    TwitterAuthProvider,
    signOut,
    User,
    onAuthStateChanged,
} from "firebase/auth";
import { defineStore } from 'pinia';
import { 
    getDatabase, 
    ref, 
    set,
} from "firebase/database";
export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    currentUser: null as User | null,
  }),
  getters: {
    isLoggedIn: (state) => state.currentUser !== null,
    user:(state)=>state.currentUser,
    userName:(state)=> { return state.currentUser !== null ? state.currentUser?.displayName : "" },
  },
  actions: {
    async signIn() {
        try {
            const auth = getAuth();
            const provider = new TwitterAuthProvider();
            const res = await signInWithPopup(auth,provider);
            this.$patch({currentUser:res.user});

            const database = getDatabase();
            const path = 'users/' + res.user.uid + "/base";
            const Ref = ref(database,path);
            set(
                Ref,{uid:res.user.uid,displayname:res.user.displayName,photourl:res.user.photoURL}
            ).then((result)=>{
                console.log( "user entered", result);
            });
        }catch(err){
            if(err instanceof Error) {
                // const errorStore = useErrorStore();
                // errorStore.setError(err);
                console.log(err);
                return;
            }
            throw err;
        }
    },
    async signOut(){
        try {
            const auth = getAuth();

            await signOut(auth);
            this.$reset();
        }catch(err){
            if(err instanceof Error){
                // const errorStore = useErrorStore();
                // errorStore.setError(err);
                return;
            }
            throw err;
        }
    },
    async getAuthState(){
        return new Promise((resolve,reject)=>{
            const auth = getAuth();

            onAuthStateChanged(auth,
                (user)=>{
                    this.$patch({currentUser:user});
                    resolve(user);
                },
                (error)=>{
                    // const errorStore = useErrorStore();
                    // errorStore.setError(error);
                    reject(error);
                });
        });
    }
  },
});