import {
    // KAZE,
    NAKI,
    TYPE,
} from "./tenhou_types"

import {
    d_tenhou_inv,
    d_hai_sort,
} from "./tenhou_dic"



export class GameInfo
{
    stuts:any;
    points:any;
    doras:any;
    uradoras:any;
    result:any;

    
    constructor(){
        this.stuts = [];
        this.points =[];
        this.doras=[];
        this.uradoras=[];
        this.result=[];
    }
    GetStartPlayer()
    {
        return this.stuts[0]%4;
    }

    GetDumpStr()
    {
        let str ="";
        str += "stuts" + "\n";
        str += this.stuts + "\n";
        str += "points"  + "\n";
        str += this.points + "\n";
        str += "doras" + "\n";
        str += this.doras + "\n";
        str += "uradoras" + "\n";
        str += this.uradoras + "\n";
        str += "result" + "\n";
        str += this.result + "\n";
        return str;
    }

    Dump()
    {
        console.log(this.GetDumpStr());
        // console.log("stuts",this.stuts);
        // console.log("points",this.points);
        // console.log("doras",this.doras);
        // console.log("uradoras",this.uradoras);
        // console.log("result",this.result);
    }
}

export class TenhouHeader
{
    title:string;
    name:string[];
    rule:string;

    constructor(){
        this.title = "";
        this.name = [];
        this.rule = "";
    }
    Set(title:any,name:any,rule:any)
    {
        this.title = title;
        this.name = name;
        this.rule = rule;
    }

    GetDumpStr()
    {
        let str ="";
        str += "title" + "\n";
        str += this.title + "\n";
        str += "name" + "\n";
        str += this.name  + "\n";
        str += "rule" + "\n";
        str += this.rule;  
        return str;
    }

    Dump()
    {
        console.log(this.GetDumpStr());
        // console.log("title",this.title);
        // console.log("name",this.name);
        // console.log("rule",this.rule);
    }
}


export class PlayData
{
    haipai:string[];
    tsumohai:string[];
    sutehai:string[];

    constructor(){
        this.haipai = [];
        this.tsumohai=[];
        this.sutehai=[];
    }

    GetDumpStr()
    {
        let str = "";
        str += "haipai" + "\n";
        str += this.haipai + "\n";
        str += "tsumohai" + "\n";
        str += this.tsumohai + "\n";
        str += "sutehai" + "\n";
        str += this.sutehai + "\n";
        return str;
    }

    Dump()
    {
        console.log(this.GetDumpStr());

        // console.log(this.haipai);
        // console.log(this.tsumohai);
        // console.log(this.sutehai);
    }

    Copy(a:PlayData)
    {
        this.haipai     = Object.assign([],a.haipai);
        this.tsumohai   = Object.assign([],a.tsumohai);
        this.sutehai    = Object.assign([],a.sutehai);
    }

    IsEnd():boolean
    {
        return this.IsEmpty(TYPE.TSUMOHAI)&&this.IsEmpty(TYPE.SUTEHAI);
    }

    IsEmpty(type:symbol):boolean
    {
        switch(type)
        {
            case TYPE.TSUMOHAI:
                {
                    return (this.tsumohai.length==0);                    
                }
            case TYPE.SUTEHAI:
                {
                    return (this.sutehai.length==0);                    
                }
        }
        return true;
    }

    PopCheck(type:symbol):string|null
    {
        switch(type)
        {
            case TYPE.TSUMOHAI:
            {
                if(this.tsumohai.length>0)
                {
                    return this.tsumohai[0];
                }       
            }
            break;
            case TYPE.SUTEHAI:
                {
                    if(this.sutehai.length>0)
                    {
                        return this.sutehai[0];
                    }       
                }
                break;
        }
        return null;
    }
    Pop(type:symbol):string|null
    {
        let check = false;
        switch(type)
        {
            case TYPE.TSUMOHAI:
            {
                if(this.tsumohai.length>0)
                {
                    const hai = this.tsumohai.shift();
                    if(hai===undefined){return null;}
                    return hai;
                }
                else
                {
                    check = true;
                }
            }
            break;
            case TYPE.SUTEHAI:
                {
                    if(this.sutehai.length>0)
                    {
                        const hai = this.sutehai.shift();
                        if(hai===undefined){return null;}
                        
                        return hai;
                    }       
                    else
                    {
                        check = true;
                    }
                }
                break;
        }

        if(check){console.warn('Array size zero');}

        return null;
    }
}

export class Game
{
    info:GameInfo;
    playdatas:PlayData[];

    constructor(){
        this.info = new GameInfo();
        this.playdatas = [new PlayData(),new PlayData(),new PlayData(),new PlayData()];
    }

    GetDumpStr()
    {
        let str = "";
        str += this.info?.GetDumpStr();
        for (let i = 0; i < this.playdatas.length; i++)
        {
            str += "==" + "player"+(i+1) + "==" + "\n";
            str += this.playdatas[i].GetDumpStr();
        }
        return str;
    }

    Dump()
    {
        console.log(this.GetDumpStr());
    }
}

export function logToGame(logString:string):Game
{
    const ret = new Game();
    const list = JSON.parse(logString);

    ret.info.stuts      = Object.assign([],list[0]);
    ret.info.points     = Object.assign([],list[1]);
    ret.info.doras      = Object.assign([],list[2]);
    ret.info.uradoras   = Object.assign([],list[3]);
    ret.info.result     = Object.assign([],list[16]);

    for (let i = 0; i < 4; i++)
    {
        ret.playdatas[i].haipai     = Object.assign([],list[4+i*3]);
        ret.playdatas[i].tsumohai   = Object.assign([],list[4+i*3+1]);
        ret.playdatas[i].sutehai    = Object.assign([],list[4+i*3+2]);        
    }

    return ret;
}

export class TenhouFormat
{
    title:any;
    name:any;
    rule:any;
    log:any;

    header:TenhouHeader;
    games:Game[];

    info:GameInfo;
    playdatas:PlayData[];

    constructor(){
        this.title = "";
        this.name = [];
        this.rule = "";
        this.log = "";

        this.header = new TenhouHeader();
        this.games = [];

        this.info = new GameInfo();
        this.playdatas = [new PlayData(),new PlayData(),new PlayData(),new PlayData()];
    }
    GetDumpStr()
    {
        let ret = "";
        ret += "header" + "\n";
        ret += this.header.GetDumpStr()+ "\n";
        ret += "gamenum" + "\n";
        ret += this.GetGameCount();

        return ret;
    }
    Dump():void
    {
        let str ="";
        str += "data" + "\n";
        str += this.GetDumpStr();
        // console.log(str);
        // console.log("title",this.title);
        // console.log("name",this.name);
        // console.log("rurle",this.rule);
        // console.log("log",this.log);

        // console.log(this.info);
        // console.log(this.playdatas);

        // this.header.Dump();
        // for ( const game of this.games)
        // {
            // game.Dump();
        // }
    }
    GetGameCount():number
    {
        return this.games.length;
    }
    GetGameByJsonStr(index:number):string
    {
        const log = [];
        log.push(this.log[index]);

        const ret = {
            'title':this.title,
            'name':this.name,
            'rule':this.rule,
            'log':log,
        };
        
        return JSON.stringify(ret);
    }
    GetStutsList()
    {
        const ret = [];
        for ( const game of this.games)
        {
            ret.push(game.info.stuts);
        }
        return ret;
    }

    Set(json:string):void
    {
        // console.log("json",json);

        const obj = JSON.parse(json);

        this.title = obj.title;
        this.name = obj.name;
        this.log = obj.log;
        this.rule = obj.rule;

        this.header.title = obj.title;
        this.header.name = obj.name;
        this.header.rule = obj.rule;

        this.games = [];

        for ( const log of obj.log)
        {
            const game = logToGame(JSON.stringify(log));
            this.games.push(game);
        }

        const list = Object.assign([],this.log[0]);

        this.info.stuts = Object.assign([],list[0]);
        this.info.points = Object.assign([],list[1]);
        this.info.doras = Object.assign([],list[2]);
        this.info.uradoras = Object.assign([],list[3]);

        for (let i = 0; i < 4; i++)
        {
            this.playdatas[i].haipai = Object.assign([],list[4+i*3]);
            this.playdatas[i].tsumohai = Object.assign([],list[4+i*3+1]);
            this.playdatas[i].sutehai = Object.assign([],list[4+i*3+2]);        
        }

        this.Dump();
    }
    GetPlayDatas():PlayData[]
    {
        return this.playdatas;
    }
    GetStartPlayer():number
    {
        return this.info.GetStartPlayer();
    }
}

export class NakiData
{
    from:number;
    hais:string[];
    naki:symbol;

    constructor(){
        this.from = -1; // 0:自分 1:上家 2:対面 3:下家.
        this.hais =[];
        this.naki = NAKI.INVALID;
    }

    GetFuroHai()
    {
        if(this.from ==-1){ return "";}
        else if(this.naki == NAKI.KAKAN)
        {
            return this.hais[3];            
        }
        return (this.hais[3-this.from]);
    }
    GetFuroHaiSubList()
    {
        if(this.naki===NAKI.ANKAN)
        {
            const hais_ = Object.assign([],this.hais);
            return hais_;
        }
        else
        {
            const hais_ = Object.assign([],this.hais);
            const index = 3 - this.from;
            hais_.splice(index,1);
        return hais_;
        }
    }
    static FromString(str:string):NakiData
    {
        const naki = new NakiData();

        if(typeof str != "string")
        {
            return naki;
        }

        if (this.IsAnkan(str))
        {
            naki.naki = NAKI.ANKAN;
            naki.from = 0;

            const hai_index = str.indexOf('a');
            const hai = str.substr(hai_index+1,2);
            // console.warn(hai);
            const res = Number(hai);
            if (!isNaN(res))
            {
                const hai_ = d_tenhou_inv[hai];
                let hai1 = hai_;
                let hai2 = hai_;

                if (hai_==='0m' || hai_==='5m')
                {
                    hai1 = '0m';
                    hai2 = '5m';
                }
                else if (hai_==='0p' || hai_==='5p')
                {
                    hai1 = '0p';
                    hai2 = '5p';
                }
                else if (hai_ ==='0s' || hai_==='5s')
                {
                    hai1 = '0s';
                    hai2 = '5s';
                }

                naki.hais.push(hai2);
                naki.hais.push(hai1);
                naki.hais.push(hai2);
                naki.hais.push(hai2);
            }
            // console.warn(naki);
        }
        else if (this.IsKakan(str))
        {
            naki.naki = NAKI.KAKAN;
            //naki.from = 3;

            const hai_index = str.indexOf('k');
            naki.from = 3 - (hai_index / 2);
            // var removed = str.splice(hai_index, 1);
            const removed = str.replace('k','');

            for (let i = 0; i < 4; i++)
            {
                const hai = str.substr(i*2,2);
                const res = Number(hai);
                if (!isNaN(res))
                {
                    naki.hais.push(d_tenhou_inv[hai]);
                }
            }
            // console.warn(naki);
        }
        else if (this.IsDaiminkan(str))
        {
            naki.naki = NAKI.MINKAN;
            naki.from = 3;

            const hai_index = str.indexOf('m');
            naki.from = 3 - (hai_index / 2);
            // var removed = str.splice(hai_index, 1);
            const removed = str.replace('m','');
            for (let i = 0; i < 4; i++)
            {
                const hai = removed.substr(i * 2, 2);
                const res = Number(hai);
                if (!isNaN(res))
                {
                    naki.hais.push(d_tenhou_inv[hai]);
                }
            }
            // console.warn(naki);
        }
        else if (this.IsChi(str))
        {
            // console.log(str);
            naki.naki = NAKI.CHI;
            naki.from = 3;

            const hai_index = str.indexOf('c');
            naki.from = 3 - (hai_index / 2);
            // var removed = str.splice(hai_index, 1);
            // str.splice(hai_index, 1);
            // var removed = str;
            const removed = str.replace('c','');
            // console.log(removed);
            for (let i = 0; i < 3; i++)
            {
                const hai = removed.substr(i * 2, 2);
                const res = Number(hai);
                if (!isNaN(res))
                {
                    naki.hais.push(d_tenhou_inv[hai]);
                }
            }
            // console.warn(naki);
        }
        else if (this.IsPon(str))
        {
            naki.naki = NAKI.PON;
            naki.from = 3;

            const hai_index = str.indexOf('p');
            naki.from = 3 - (hai_index / 2);
            // var removed = str.splice(hai_index, 1);
            const removed = str.replace('p','');

            for (let i = 0; i < 3; i++)
            {
                const hai = removed.substr(i * 2, 2);
                const res = Number(hai);
                if (!isNaN(res))
                {
                    naki.hais.push(d_tenhou_inv[hai]);
                }
            }
            // console.warn(naki);
        }
        return naki;
    }

    static  IsAnkan(str:string):boolean
    {
        if(!isNaN(Number(str))){return false;}
        return str.indexOf('a')!=-1;
    }
    static  IsKakan(str:string):boolean
    {
        if(!isNaN(Number(str))){return false;}
        return str.indexOf('k')!=-1;
    }
    static  IsDaiminkan(str:string):boolean
    {
        if(!isNaN(Number(str))){return false;}
        return str.indexOf('m')!=-1;
    }
    static  IsChi(str:string):boolean
    {
        if(!isNaN(Number(str))){return false;}
        // console.log(str);
        // console.log( str.indexOf('c'));
        return str.indexOf('c')!=-1;
    }
    static  IsPon(str:string):boolean
    {
        if(!isNaN(Number(str))){return false;}
        return str.indexOf('p')!=-1;
    }
}

export class ScenePlayerData
{
    tsumo:string;
    tehai:string[];
    sutehai:string[];
    naki:NakiData[];

    constructor(){
        this.tsumo="";
        this.tehai=[];
        this.sutehai =[];
        this.naki=[];
    }
    Copy(a:ScenePlayerData):void
    {
        this.tsumo = a.tsumo;
        this.tehai = Object.assign([],a.tehai);
        this.sutehai = Object.assign([],a.sutehai);
        this.naki = Object.assign([],a.naki);
    }

    SetInitialDataFromPlayData(playdata:PlayData):void
    {
        // this.tehai = Object.assign([],playdata.haipai);
        this.tehai=[];
        this.sutehai=[];
        this.naki=[];
        this.tsumo="";

        for ( let i = 0; i < playdata.haipai.length; i++)
        {
            const hai = playdata.haipai[i];
            this.tehai.push(d_tenhou_inv[String(hai)]);
        }

    }

    TehaiSort():void
    {
        this.tehai.sort(function(a, b) { return d_hai_sort[a] - d_hai_sort[b];});
    }
    AddTehai(hai:string):void
    {
        this.tehai.push(hai);
        this.TehaiSort();
    }
    SetTsumo(hai:string):void
    {
        this.tsumo = hai;
    }
    RemoveTsumo():void
    {
        this.tsumo = "";
    }
    _AddNaki(naki:NakiData):void
    {
        this.naki.push(naki);
        // this.SetLastPlayedHai(naki.GetFuroHai());
    }
    _RemoveFuroHai(naki:NakiData):void
    {
        const sublist = naki.GetFuroHaiSubList();
        // console.warn(sublist);
        for ( const h in sublist)
        {
            // console.warn(sublist[h]);
            const index = this.tehai.indexOf(sublist[h]);
            // console.warn(index);
            this.tehai.splice(index,1);
        }
    }
    AddKakan(naki:NakiData)
    {
        this.AddTsumoToTehai();

        const index = this.tehai.indexOf(naki.GetFuroHai());
        this.tehai.splice(index,1);
        this._AddNaki(naki);
    }
    AddAnkan(naki:NakiData)
    {
        this.AddTsumoToTehai();
        this._AddNaki(naki);
        this._RemoveFuroHai(naki);
    }
    AddFuroFromOther(naki:NakiData)
    {
        this._AddNaki(naki);
        this._RemoveFuroHai(naki);
    }
    AddSutehai(sutehai:string)
    {
        this.sutehai.push(sutehai);
    }
    AddTsumoToTehai()
    {
        if(this.tsumo==='')
        {
            console.warn('tsumo empty');
        }
        else
        {
        this.tehai.push(this.tsumo);
        this.RemoveTsumo();
        this.TehaiSort();
        }
    }
    AddNakareInfo()
    {
        const length = this.sutehai.length;
        this.sutehai[length - 1] = "N" + this.sutehai[length - 1];
    }

    Dahai(hai:any,furo:any,tsumogiri:any,reach:any)
    {
        let sutehai ="";
        if(reach)
        {
            sutehai = sutehai + 'r';
        }
        if(tsumogiri)
        {
            sutehai = sutehai + 'Q';
        }
        if(tsumogiri)
        {
            hai = this.tsumo;
        }
        sutehai = sutehai + hai;
        this.sutehai.push(sutehai);
        if(!furo)
        {
            if(!tsumogiri)
            {
                this.AddTsumoToTehai();
                const index = this.tehai.indexOf(hai);
                this.tehai.splice(index,1);
            }
            this.tsumo="";
        }
        else
        {
            const index = this.tehai.indexOf(hai);
            this.tehai.splice(index,1);
        }
    }
}
