import {defineStore} from "pinia"
import * as PIXI from "pixi.js"

interface State{
    images:any;
    paths:Map<string,string>;
    textures:Map<string,PIXI.Texture>;
    isLoading:boolean;
}

export const useResourcesStore = defineStore({
    id:"resources",
    state:():State=>{
        return {
            images:[],
            paths:new Map<string,string>(),
            textures:new Map<string,PIXI.Texture>(),
            isLoading:true,
        }
    },
    getters:{
        getimages:(state)=>{
            return ():Map<string,string> =>{
                const images = state.images as Map<string,string>;
                return images;
            }
        },
        getpaths:(state)=> {
            return ():Map<string,string> =>{
                return state.paths;
            }
        },
        gettextures:(state)=>{
            return ():Map<string,PIXI.Texture> =>{
                const textures = state.textures as Map<string,PIXI.Texture>;
                return textures;
            }
        },           
    },
    actions:{
        prepare():void{
            // console.log("prepare");
            this.prepareImagePaths();
            this.prepareImages();
        },
        prepareImagePaths():void{
            this.paths.set('1000t', 'pai-images/tenbou_1000.png');
            this.paths.set('100t', 'pai-images/tenbou_100.png');

            this.paths.set('0z', '/pai-images/ji8-66-90-s-emb.png');
            this.paths.set('1z', '/pai-images/ji1-66-90-s-emb.png');
            this.paths.set('2z', '/pai-images/ji2-66-90-s-emb.png');
            this.paths.set('3z', '/pai-images/ji3-66-90-s-emb.png');
            this.paths.set('4z', '/pai-images/ji4-66-90-s-emb.png');
            this.paths.set('5z', '/pai-images/ji5-66-90-s-emb.png');
            this.paths.set('6z', '/pai-images/ji6-66-90-s-emb.png');
            this.paths.set('7z', '/pai-images/ji7-66-90-s-emb.png');
            this.paths.set('1m', '/pai-images/man1-66-90-s-emb.png');
            this.paths.set('2m', '/pai-images/man2-66-90-s-emb.png');
            this.paths.set('3m', '/pai-images/man3-66-90-s-emb.png');
            this.paths.set('4m', '/pai-images/man4-66-90-s-emb.png');
            this.paths.set('5m', '/pai-images/man5-66-90-s-emb.png');
            this.paths.set('6m', '/pai-images/man6-66-90-s-emb.png');
            this.paths.set('7m', '/pai-images/man7-66-90-s-emb.png');
            this.paths.set('8m', '/pai-images/man8-66-90-s-emb.png');
            this.paths.set('9m', '/pai-images/man9-66-90-s-emb.png');
            this.paths.set('1p', '/pai-images/pin1-66-90-s-emb.png');
            this.paths.set('2p', '/pai-images/pin2-66-90-s-emb.png');
            this.paths.set('3p', '/pai-images/pin3-66-90-s-emb.png');
            this.paths.set('4p', '/pai-images/pin4-66-90-s-emb.png');
            this.paths.set('5p', '/pai-images/pin5-66-90-s-emb.png');
            this.paths.set('6p', '/pai-images/pin6-66-90-s-emb.png');
            this.paths.set('7p', '/pai-images/pin7-66-90-s-emb.png');
            this.paths.set('8p', '/pai-images/pin8-66-90-s-emb.png');
            this.paths.set('9p', '/pai-images/pin9-66-90-s-emb.png');
            this.paths.set('1s', '/pai-images/sou1-66-90-s-emb.png');
            this.paths.set('2s', '/pai-images/sou2-66-90-s-emb.png');
            this.paths.set('3s', '/pai-images/sou3-66-90-s-emb.png');
            this.paths.set('4s', '/pai-images/sou4-66-90-s-emb.png');
            this.paths.set('5s', '/pai-images/sou5-66-90-s-emb.png');
            this.paths.set('6s', '/pai-images/sou6-66-90-s-emb.png');
            this.paths.set('7s', '/pai-images/sou7-66-90-s-emb.png');
            this.paths.set('8s', '/pai-images/sou8-66-90-s-emb.png');
            this.paths.set('9s', '/pai-images/sou9-66-90-s-emb.png');
            this.paths.set('0p', '/pai-images/aka1-66-90-s-emb.png');
            this.paths.set('0s', '/pai-images/aka2-66-90-s-emb.png');
            this.paths.set('0m', '/pai-images/aka3-66-90-s-emb.png');

            this.paths.set('0z_', '/pai-images/ji8-66-90-s-yoko-emb.png');
            this.paths.set('1z_', '/pai-images/ji1-66-90-s-yoko-emb.png');
            this.paths.set('2z_', '/pai-images/ji2-66-90-s-yoko-emb.png');
            this.paths.set('3z_', '/pai-images/ji3-66-90-s-yoko-emb.png');
            this.paths.set('4z_', '/pai-images/ji4-66-90-s-yoko-emb.png');
            this.paths.set('5z_', '/pai-images/ji5-66-90-s-yoko-emb.png');
            this.paths.set('6z_', '/pai-images/ji6-66-90-s-yoko-emb.png');
            this.paths.set('7z_', '/pai-images/ji7-66-90-s-yoko-emb.png');
            this.paths.set('1m_', '/pai-images/man1-66-90-s-yoko-emb.png');
            this.paths.set('2m_', '/pai-images/man2-66-90-s-yoko-emb.png');
            this.paths.set('3m_', '/pai-images/man3-66-90-s-yoko-emb.png');
            this.paths.set('4m_', '/pai-images/man4-66-90-s-yoko-emb.png');
            this.paths.set('5m_', '/pai-images/man5-66-90-s-yoko-emb.png');
            this.paths.set('6m_', '/pai-images/man6-66-90-s-yoko-emb.png');
            this.paths.set('7m_', '/pai-images/man7-66-90-s-yoko-emb.png');
            this.paths.set('8m_', '/pai-images/man8-66-90-s-yoko-emb.png');
            this.paths.set('9m_', '/pai-images/man9-66-90-s-yoko-emb.png');
            this.paths.set('1p_', '/pai-images/pin1-66-90-s-yoko-emb.png');
            this.paths.set('2p_', '/pai-images/pin2-66-90-s-yoko-emb.png');
            this.paths.set('3p_', '/pai-images/pin3-66-90-s-yoko-emb.png');
            this.paths.set('4p_', '/pai-images/pin4-66-90-s-yoko-emb.png');
            this.paths.set('5p_', '/pai-images/pin5-66-90-s-yoko-emb.png');
            this.paths.set('6p_', '/pai-images/pin6-66-90-s-yoko-emb.png');
            this.paths.set('7p_', '/pai-images/pin7-66-90-s-yoko-emb.png');
            this.paths.set('8p_', '/pai-images/pin8-66-90-s-yoko-emb.png');
            this.paths.set('9p_', '/pai-images/pin9-66-90-s-yoko-emb.png');
            this.paths.set('1s_', '/pai-images/sou1-66-90-s-yoko-emb.png');
            this.paths.set('2s_', '/pai-images/sou2-66-90-s-yoko-emb.png');
            this.paths.set('3s_', '/pai-images/sou3-66-90-s-yoko-emb.png');
            this.paths.set('4s_', '/pai-images/sou4-66-90-s-yoko-emb.png');
            this.paths.set('5s_', '/pai-images/sou5-66-90-s-yoko-emb.png');
            this.paths.set('6s_', '/pai-images/sou6-66-90-s-yoko-emb.png');
            this.paths.set('7s_', '/pai-images/sou7-66-90-s-yoko-emb.png');
            this.paths.set('8s_', '/pai-images/sou8-66-90-s-yoko-emb.png');
            this.paths.set('9s_', '/pai-images/sou9-66-90-s-yoko-emb.png');
            this.paths.set('0p_', '/pai-images/aka1-66-90-s-yoko-emb.png');
            this.paths.set('0s_', '/pai-images/aka2-66-90-s-yoko-emb.png');
            this.paths.set('0m_', '/pai-images/aka3-66-90-s-yoko-emb.png');
        },
        prepareImages():void{
            // this.images['1000t']    = require('@/assets/img/pai-images/tenbou_1000.png');
            // this.images['100t']     = require('@/assets/img/pai-images/tenbou_100.png');
            // this.images['0z']       = require('@/assets/img/pai-images/ji8-66-90-s-emb.png');
            // this.images['1z']       = require('@/assets/img/pai-images/ji1-66-90-s-emb.png');
            // this.images['2z']       = require('@/assets/img/pai-images/ji2-66-90-s-emb.png');
            // this.images['3z']       = require('@/assets/img/pai-images/ji3-66-90-s-emb.png');
            // this.images['4z']       = require('@/assets/img/pai-images/ji4-66-90-s-emb.png');
            // this.images['5z']       = require('@/assets/img/pai-images/ji5-66-90-s-emb.png');
            // this.images['6z']       = require('@/assets/img/pai-images/ji6-66-90-s-emb.png');
            // this.images['7z']       = require('@/assets/img/pai-images/ji7-66-90-s-emb.png');
            // this.images['1m']       = require('@/assets/img/pai-images/man1-66-90-s-emb.png');
            // this.images['2m']       = require('@/assets/img/pai-images/man2-66-90-s-emb.png');
            // this.images['3m']       = require('@/assets/img/pai-images/man3-66-90-s-emb.png');
            // this.images['4m']       = require('@/assets/img/pai-images/man4-66-90-s-emb.png');
            // this.images['5m']       = require('@/assets/img/pai-images/man5-66-90-s-emb.png');
            // this.images['6m']       = require('@/assets/img/pai-images/man6-66-90-s-emb.png');
            // this.images['7m']       = require('@/assets/img/pai-images/man7-66-90-s-emb.png');
            // this.images['8m']       = require('@/assets/img/pai-images/man8-66-90-s-emb.png');
            // this.images['9m']       = require('@/assets/img/pai-images/man9-66-90-s-emb.png');
            // this.images['1p']       = require('@/assets/img/pai-images/pin1-66-90-s-emb.png');
            // this.images['2p']       = require('@/assets/img/pai-images/pin2-66-90-s-emb.png');
            // this.images['3p']       = require('@/assets/img/pai-images/pin3-66-90-s-emb.png');
            // this.images['4p']       = require('@/assets/img/pai-images/pin4-66-90-s-emb.png');
            // this.images['5p']       = require('@/assets/img/pai-images/pin5-66-90-s-emb.png');
            // this.images['6p']       = require('@/assets/img/pai-images/pin6-66-90-s-emb.png');
            // this.images['7p']       = require('@/assets/img/pai-images/pin7-66-90-s-emb.png');
            // this.images['8p']       = require('@/assets/img/pai-images/pin8-66-90-s-emb.png');
            // this.images['9p']       = require('@/assets/img/pai-images/pin9-66-90-s-emb.png');
            // this.images['1s']       = require('@/assets/img/pai-images/sou1-66-90-s-emb.png');
            // this.images['2s']       = require('@/assets/img/pai-images/sou2-66-90-s-emb.png');
            // this.images['3s']       = require('@/assets/img/pai-images/sou3-66-90-s-emb.png');
            // this.images['4s']       = require('@/assets/img/pai-images/sou4-66-90-s-emb.png');
            // this.images['5s']       = require('@/assets/img/pai-images/sou5-66-90-s-emb.png');
            // this.images['6s']       = require('@/assets/img/pai-images/sou6-66-90-s-emb.png');
            // this.images['7s']       = require('@/assets/img/pai-images/sou7-66-90-s-emb.png');
            // this.images['8s']       = require('@/assets/img/pai-images/sou8-66-90-s-emb.png');
            // this.images['9s']       = require('@/assets/img/pai-images/sou9-66-90-s-emb.png');
            // this.images['0p']       = require('@/assets/img/pai-images/aka1-66-90-s-emb.png');
            // this.images['0s']       = require('@/assets/img/pai-images/aka2-66-90-s-emb.png');
            // this.images['0m']       = require('@/assets/img/pai-images/aka3-66-90-s-emb.png');
            // this.images['0z_']      = require('@/assets/img/pai-images/ji8-66-90-s-yoko-emb.png');
            // this.images['1z_']      = require('@/assets/img/pai-images/ji1-66-90-s-yoko-emb.png');
            // this.images['2z_']      = require('@/assets/img/pai-images/ji2-66-90-s-yoko-emb.png');
            // this.images['3z_']      = require('@/assets/img/pai-images/ji3-66-90-s-yoko-emb.png');
            // this.images['4z_']      = require('@/assets/img/pai-images/ji4-66-90-s-yoko-emb.png');
            // this.images['5z_']      = require('@/assets/img/pai-images/ji5-66-90-s-yoko-emb.png');
            // this.images['6z_']      = require('@/assets/img/pai-images/ji6-66-90-s-yoko-emb.png');
            // this.images['7z_']      = require('@/assets/img/pai-images/ji7-66-90-s-yoko-emb.png');
            // this.images['1m_']      = require('@/assets/img/pai-images/man1-66-90-s-yoko-emb.png');
            // this.images['2m_']      = require('@/assets/img/pai-images/man2-66-90-s-yoko-emb.png');
            // this.images['3m_']      = require('@/assets/img/pai-images/man3-66-90-s-yoko-emb.png');
            // this.images['4m_']      = require('@/assets/img/pai-images/man4-66-90-s-yoko-emb.png');
            // this.images['5m_']      = require('@/assets/img/pai-images/man5-66-90-s-yoko-emb.png');
            // this.images['6m_']      = require('@/assets/img/pai-images/man6-66-90-s-yoko-emb.png');
            // this.images['7m_']      = require('@/assets/img/pai-images/man7-66-90-s-yoko-emb.png');
            // this.images['8m_']      = require('@/assets/img/pai-images/man8-66-90-s-yoko-emb.png');
            // this.images['9m_']      = require('@/assets/img/pai-images/man9-66-90-s-yoko-emb.png');
            // this.images['1p_']      = require('@/assets/img/pai-images/pin1-66-90-s-yoko-emb.png');
            // this.images['2p_']      = require('@/assets/img/pai-images/pin2-66-90-s-yoko-emb.png');
            // this.images['3p_']      = require('@/assets/img/pai-images/pin3-66-90-s-yoko-emb.png');
            // this.images['4p_']      = require('@/assets/img/pai-images/pin4-66-90-s-yoko-emb.png');
            // this.images['5p_']      = require('@/assets/img/pai-images/pin5-66-90-s-yoko-emb.png');
            // this.images['6p_']      = require('@/assets/img/pai-images/pin6-66-90-s-yoko-emb.png');
            // this.images['7p_']      = require('@/assets/img/pai-images/pin7-66-90-s-yoko-emb.png');
            // this.images['8p_']      = require('@/assets/img/pai-images/pin8-66-90-s-yoko-emb.png');
            // this.images['9p_']      = require('@/assets/img/pai-images/pin9-66-90-s-yoko-emb.png');
            // this.images['1s_']      = require('@/assets/img/pai-images/sou1-66-90-s-yoko-emb.png');
            // this.images['2s_']      = require('@/assets/img/pai-images/sou2-66-90-s-yoko-emb.png');
            // this.images['3s_']      = require('@/assets/img/pai-images/sou3-66-90-s-yoko-emb.png');
            // this.images['4s_']      = require('@/assets/img/pai-images/sou4-66-90-s-yoko-emb.png');
            // this.images['5s_']      = require('@/assets/img/pai-images/sou5-66-90-s-yoko-emb.png');
            // this.images['6s_']      = require('@/assets/img/pai-images/sou6-66-90-s-yoko-emb.png');
            // this.images['7s_']      = require('@/assets/img/pai-images/sou7-66-90-s-yoko-emb.png');
            // this.images['8s_']      = require('@/assets/img/pai-images/sou8-66-90-s-yoko-emb.png');
            // this.images['9s_']      = require('@/assets/img/pai-images/sou9-66-90-s-yoko-emb.png');
            // this.images['0p_']      = require('@/assets/img/pai-images/aka1-66-90-s-yoko-emb.png');
            // this.images['0s_']      = require('@/assets/img/pai-images/aka2-66-90-s-yoko-emb.png');
            // this.images['0m_']      = require('@/assets/img/pai-images/aka3-66-90-s-yoko-emb.png');
            this.images['1000t']    = require('@/assets/img/pai-images/tenbou_1000.png');
            this.images['100t']     = require('@/assets/img/pai-images/tenbou_100.png');
            this.images['0z']       = require('@/assets/img/pai-images/ji8-66-90-s-emb.png');
            this.images['1z']       = require('@/assets/img/pai-images/ji1-66-90-s-emb.png');
            this.images['2z']       = require('@/assets/img/pai-images/ji2-66-90-s-emb.png');
            this.images['3z']       = require('@/assets/img/pai-images/ji3-66-90-s-emb.png');
            this.images['4z']       = require('@/assets/img/pai-images/ji4-66-90-s-emb.png');
            this.images['5z']       = require('@/assets/img/pai-images/ji5-66-90-s-emb.png');
            this.images['6z']       = require('@/assets/img/pai-images/ji6-66-90-s-emb.png');
            this.images['7z']       = require('@/assets/img/pai-images/ji7-66-90-s-emb.png');
            this.images['1m']       = require('@/assets/img/pai-images/man1-66-90-s-emb.png');
            this.images['2m']       = require('@/assets/img/pai-images/man2-66-90-s-emb.png');
            this.images['3m']       = require('@/assets/img/pai-images/man3-66-90-s-emb.png');
            this.images['4m']       = require('@/assets/img/pai-images/man4-66-90-s-emb.png');
            this.images['5m']       = require('@/assets/img/pai-images/man5-66-90-s-emb.png');
            this.images['6m']       = require('@/assets/img/pai-images/man6-66-90-s-emb.png');
            this.images['7m']       = require('@/assets/img/pai-images/man7-66-90-s-emb.png');
            this.images['8m']       = require('@/assets/img/pai-images/man8-66-90-s-emb.png');
            this.images['9m']       = require('@/assets/img/pai-images/man9-66-90-s-emb.png');
            this.images['1p']       = require('@/assets/img/pai-images/pin1-66-90-s-emb.png');
            this.images['2p']       = require('@/assets/img/pai-images/pin2-66-90-s-emb.png');
            this.images['3p']       = require('@/assets/img/pai-images/pin3-66-90-s-emb.png');
            this.images['4p']       = require('@/assets/img/pai-images/pin4-66-90-s-emb.png');
            this.images['5p']       = require('@/assets/img/pai-images/pin5-66-90-s-emb.png');
            this.images['6p']       = require('@/assets/img/pai-images/pin6-66-90-s-emb.png');
            this.images['7p']       = require('@/assets/img/pai-images/pin7-66-90-s-emb.png');
            this.images['8p']       = require('@/assets/img/pai-images/pin8-66-90-s-emb.png');
            this.images['9p']       = require('@/assets/img/pai-images/pin9-66-90-s-emb.png');
            this.images['1s']       = require('@/assets/img/pai-images/sou1-66-90-s-emb.png');
            this.images['2s']       = require('@/assets/img/pai-images/sou2-66-90-s-emb.png');
            this.images['3s']       = require('@/assets/img/pai-images/sou3-66-90-s-emb.png');
            this.images['4s']       = require('@/assets/img/pai-images/sou4-66-90-s-emb.png');
            this.images['5s']       = require('@/assets/img/pai-images/sou5-66-90-s-emb.png');
            this.images['6s']       = require('@/assets/img/pai-images/sou6-66-90-s-emb.png');
            this.images['7s']       = require('@/assets/img/pai-images/sou7-66-90-s-emb.png');
            this.images['8s']       = require('@/assets/img/pai-images/sou8-66-90-s-emb.png');
            this.images['9s']       = require('@/assets/img/pai-images/sou9-66-90-s-emb.png');
            this.images['0p']       = require('@/assets/img/pai-images/aka1-66-90-s-emb.png');
            this.images['0s']       = require('@/assets/img/pai-images/aka2-66-90-s-emb.png');
            this.images['0m']       = require('@/assets/img/pai-images/aka3-66-90-s-emb.png');
            this.images['0z_']      = require('@/assets/img/pai-images/ji8-66-90-s-yoko-emb.png');
            this.images['1z_']      = require('@/assets/img/pai-images/ji1-66-90-s-yoko-emb.png');
            this.images['2z_']      = require('@/assets/img/pai-images/ji2-66-90-s-yoko-emb.png');
            this.images['3z_']      = require('@/assets/img/pai-images/ji3-66-90-s-yoko-emb.png');
            this.images['4z_']      = require('@/assets/img/pai-images/ji4-66-90-s-yoko-emb.png');
            this.images['5z_']      = require('@/assets/img/pai-images/ji5-66-90-s-yoko-emb.png');
            this.images['6z_']      = require('@/assets/img/pai-images/ji6-66-90-s-yoko-emb.png');
            this.images['7z_']      = require('@/assets/img/pai-images/ji7-66-90-s-yoko-emb.png');
            this.images['1m_']      = require('@/assets/img/pai-images/man1-66-90-s-yoko-emb.png');
            this.images['2m_']      = require('@/assets/img/pai-images/man2-66-90-s-yoko-emb.png');
            this.images['3m_']      = require('@/assets/img/pai-images/man3-66-90-s-yoko-emb.png');
            this.images['4m_']      = require('@/assets/img/pai-images/man4-66-90-s-yoko-emb.png');
            this.images['5m_']      = require('@/assets/img/pai-images/man5-66-90-s-yoko-emb.png');
            this.images['6m_']      = require('@/assets/img/pai-images/man6-66-90-s-yoko-emb.png');
            this.images['7m_']      = require('@/assets/img/pai-images/man7-66-90-s-yoko-emb.png');
            this.images['8m_']      = require('@/assets/img/pai-images/man8-66-90-s-yoko-emb.png');
            this.images['9m_']      = require('@/assets/img/pai-images/man9-66-90-s-yoko-emb.png');
            this.images['1p_']      = require('@/assets/img/pai-images/pin1-66-90-s-yoko-emb.png');
            this.images['2p_']      = require('@/assets/img/pai-images/pin2-66-90-s-yoko-emb.png');
            this.images['3p_']      = require('@/assets/img/pai-images/pin3-66-90-s-yoko-emb.png');
            this.images['4p_']      = require('@/assets/img/pai-images/pin4-66-90-s-yoko-emb.png');
            this.images['5p_']      = require('@/assets/img/pai-images/pin5-66-90-s-yoko-emb.png');
            this.images['6p_']      = require('@/assets/img/pai-images/pin6-66-90-s-yoko-emb.png');
            this.images['7p_']      = require('@/assets/img/pai-images/pin7-66-90-s-yoko-emb.png');
            this.images['8p_']      = require('@/assets/img/pai-images/pin8-66-90-s-yoko-emb.png');
            this.images['9p_']      = require('@/assets/img/pai-images/pin9-66-90-s-yoko-emb.png');
            this.images['1s_']      = require('@/assets/img/pai-images/sou1-66-90-s-yoko-emb.png');
            this.images['2s_']      = require('@/assets/img/pai-images/sou2-66-90-s-yoko-emb.png');
            this.images['3s_']      = require('@/assets/img/pai-images/sou3-66-90-s-yoko-emb.png');
            this.images['4s_']      = require('@/assets/img/pai-images/sou4-66-90-s-yoko-emb.png');
            this.images['5s_']      = require('@/assets/img/pai-images/sou5-66-90-s-yoko-emb.png');
            this.images['6s_']      = require('@/assets/img/pai-images/sou6-66-90-s-yoko-emb.png');
            this.images['7s_']      = require('@/assets/img/pai-images/sou7-66-90-s-yoko-emb.png');
            this.images['8s_']      = require('@/assets/img/pai-images/sou8-66-90-s-yoko-emb.png');
            this.images['9s_']      = require('@/assets/img/pai-images/sou9-66-90-s-yoko-emb.png');
            this.images['0p_']      = require('@/assets/img/pai-images/aka1-66-90-s-yoko-emb.png');
            this.images['0s_']      = require('@/assets/img/pai-images/aka2-66-90-s-yoko-emb.png');
            this.images['0m_']      = require('@/assets/img/pai-images/aka3-66-90-s-yoko-emb.png');
        
            // console.log("images", this.images);
            for ( const key in this.images)
            {
                // console.log("key",key, "image", this.images[key]);
                
                this.textures.set(key, PIXI.Texture.from(this.images[key]));
                // console.log("key",key,"image",image,"texture",this.textures.get(key));
            }
        }       
    }
});