// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBCHk_h4dajsquteuczShWCOcqXxfFsMKA",
  authDomain: "webtest-d01da.firebaseapp.com",
  databaseURL: "https://webtest-d01da-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "webtest-d01da",
  storageBucket: "webtest-d01da.appspot.com",
  messagingSenderId: "364699101169",
  appId: "1:364699101169:web:da89dff3b657c2b9d54650",
  measurementId: "G-XBHYT3R11E"
};